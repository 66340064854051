import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Select, Option } from "@material-tailwind/react"; 
import { Card, Typography } from "@material-tailwind/react";
import { FaCalendar, FaDownload, FaEye } from 'react-icons/fa';
import { BiSolidTrashAlt } from 'react-icons/bi';
import { apiBaseUrl as baseEndpoint } from '../../../APIs/endpoints';
import { toast, ToastContainer } from 'react-toastify';
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react";

import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const DocumentsDetails = (props) => {
    function backtoAcadamics(){
        props.get(true);
    }

    const TABLE_HEAD = ["File Name", "File Size", "Date", "Action"];

    const [documents, setDocuments] = useState([]);
    const [formData, setFormData] = useState({
        doc_name: '',
        doc_type: '',
        document: null,
        teacher: null,
        uploaed_date: '',
    });
    const [selectedDocumentName, setSelectedDocumentName] = useState("");
    const [open, setOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleOpenDialog = () => {
        setOpen(prevOpen => !prevOpen); 
    };
    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            setSelectedFile(acceptedFiles[0]);
            setSelectedDocumentName(acceptedFiles[0].name);
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: ".doc,.docx,.pdf",
        noClick: true, 
    });

    const handleCancel = () => {
        setSelectedFile(null);
        setSelectedDocumentName('');
        setOpen(false);
    };

    const handleUpload = () => {
        if (!selectedFile) {
            toast.error("Please select a file before uploading.");
            return;
        }

        const teacher = JSON.parse(localStorage.getItem("teacher"));
        if (!teacher) {
            toast.error("No Teacher information found in local storage.");
            return;
        }

        formData.teacher = teacher.id;

        const data = new FormData();
        data.append('document', selectedFile);
        data.append('doc_name', selectedFile.name);
        data.append('doc_type', formData.doc_type);
        data.append('teacher', formData.teacher);

        const endpoint = `${baseEndpoint}/api/teachers/${teacher.id}/documents/`;

        axios.post(endpoint, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
        .then(response => {
            console.log('Document uploaded successfully:', response);
            setDocuments([...documents, {
                name: selectedFile.name,
                size: selectedFile.size,
                date: new Date().toLocaleDateString(),
            }]);
            setFormData({ doc_name: '', doc_type: '', document: null, teacher: null });
            setSelectedDocumentName("");
            setSelectedFile(null);
            setOpen(false);
            toast.success("Document uploaded successfully!");
        })
        .catch((error) => {
            console.error('Error uploading document:', error.response || error);
            toast.error("Error uploading document.");
        });
    };

    const updatedoc_type = (val) => {
        setFormData({ ...formData, doc_type: val });
    };

    const navigate = useNavigate();
    function Nextbutton() {
        var teacher_id = JSON.parse(localStorage.getItem("documents"))
        localStorage.removeItem("teacher");
        localStorage.removeItem("experiences");
        localStorage.removeItem("informations");
        localStorage.removeItem("Responsibilities");
        localStorage.removeItem("documents");
        navigate(`/teachers/overview`);
    }

    const handleDelete = (index) => {
        const updatedDocuments = documents.filter((_, i) => i !== index);
        setDocuments(updatedDocuments);
    };

    return (
        <div className="w-full flex items-center justify-center">
            <form encType="multipart/form-data" className="w-full max-w-3xl">
                <div className="mb-5 flex items-center justify-between">
                    <h3 className="text-2xl font-bold">Documents</h3>
                </div>
                <div className="mb-5 flex items-center justify-between">
                    <div>
                        <select 
                            name='doc_type' 
                            onChange={(e) => updatedoc_type(e.target.value)} 
                            className="w-full h-12 bg-white border border-gray-300 rounded px-2"
                        >
                            <option value="">Select</option>
                            <option value="aadhaar">Aadhaar</option>
                            <option value="graduation-certificate">Graduation-Certificate</option>
                            <option value="study-certificate">Study-Certificate</option>
                            <option value="experience">Experience</option>
                            <option value="pan">Pan</option>
                            <option value="atheletic">Atheletic</option>
                        </select>
                    </div>
                    <div className="flex items-center space-x-4">
                        {selectedDocumentName && (
                            <span className="text-gray-600">{selectedDocumentName}</span>
                        )}
                        <label
                            className="bg-blue-600 text-white px-4 py-2 rounded cursor-pointer flex items-center space-x-2"
                            style={{ backgroundColor: '#EF805D', color: 'white' }}
                            onClick={handleOpenDialog}
                        >
                            <FaDownload />
                            <span>Upload Document</span>
                        </label>
                        <Dialog
                            open={open}
                            handler={handleOpenDialog}
                            animate={{
                                mount: { scale: 1, y: 0 },
                                unmount: { scale: 0.9, y: -100 },
                            }}
                        >
                            <DialogHeader>Upload Document</DialogHeader>
                            <DialogBody className="py-10">
                                <div
                                    {...getRootProps({ className: 'dropzone' })}
                                    className="border-2 border-dashed border-gray-300 p-6 py-10 bg-[#F0F0F0] rounded-md cursor-pointer text-center flex flex-col justify-center items-center"
                                    style={{ height: '50%' }}
                                    >
                                    <input {...getInputProps()} />
                                    <p>Drag and drop files here</p>
                                    <button
                                        type="button"
                                        className="mt-5 px-4 py-2 bg-white text-black rounded-md hover:bg-blue-600"
                                        onClick={() => document.querySelector('input[type="file"]').click()} // Correct way to trigger file selection
                                    >
                                        Browse Files
                                    </button>
                                    {selectedFile && (
                                        <p className="mt-4 text-green-600">
                                            {selectedFile.name} selected
                                        </p>
                                    )}
                                </div>
                            </DialogBody>
                            <DialogFooter className='my-10'>
                                <Button
                                    variant="text"
                                    className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
                                    onClick={handleCancel}
                                >
                                    <span>Cancel</span>
                                </Button>
                                <Button
                                    className="bg-blue-600 text-white px-4 py-2 rounded cursor-pointer"
                                    type="button"
                                    style={{ backgroundColor: '#EF805D', color: 'white' }}
                                    onClick={handleUpload}
                                    disabled={!selectedFile}
                                >
                                    <span>Upload</span>
                                </Button>
                            </DialogFooter>
                        </Dialog>
                    </div>
                </div>
                <hr />
                <br />
                <div>
                    <Card className="h-full w-full overflow-hidden">
                        <table className="w-full min-w-max table-auto text-left">
                            <thead>
                                <tr>
                                    {TABLE_HEAD.map((head) => (
                                        <th
                                            key={head}
                                            className="border-b border-blue-gray-100 p-4"
                                            style={{ backgroundColor: '#EF805D', color: 'white' }}
                                        >
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal leading-none"
                                                style={{ color: 'white' }}
                                            >
                                                {head}
                                            </Typography>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {documents.map((doc, index) => (
                                    <tr key={index}>
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal flex items-center justify-evenly"
                                            >
                                                {doc.name}
                                            </Typography>
                                        </td>
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal flex items-center justify-evenly"
                                            >
                                                {`${(doc.size / 1024).toFixed(2)} KB`} {/* Display file size in KB */}
                                            </Typography>
                                        </td>
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal flex items-center"
                                            >
                                                <FaCalendar /> {doc.date}
                                            </Typography>
                                        </td>
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <Typography
                                                as="a"
                                                href="#"
                                                variant="small"
                                                color="blue-gray"
                                                className="font-medium flex justify-evenly items-center gap-2"
                                            >
                                                <FaEye /> View
                                                <BiSolidTrashAlt onClick={() => handleDelete(index)} className="cursor-pointer text-red-500" />
                                            </Typography>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Card>
                </div>
                <div className="flex justify-end mt-5">
                <button
                        type="button"
                        className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
                        onClick={() => props.getTeacherExp()}
                    >
                        Back
                    </button>
                    <button
                        className="bg-blue-600 text-white px-4 py-2 rounded cursor-pointer"
                        type="button"
                        style={{ backgroundColor: '#EF805D', color: 'white' }}
                        onClick={Nextbutton}
                    >
                        Next
                    </button>
                </div>
            </form>
            <ToastContainer />
        </div>
    );
};

export default DocumentsDetails;
