import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Accordion, AccordionHeader, AccordionBody } from '@material-tailwind/react';
import { FaHome } from 'react-icons/fa';

const Sidebar = () => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <aside className="bg-gray-100 text-gray-800 flex flex-col h-full w-1/6 fixed top-16">
      <nav id="sidebar" className="flex flex-col p-3">
        <div className="text-center mt-4 ml-5">
          <Accordion>
            <AccordionHeader onClick={() => handleNavigate('/school')} className="border-b-0 p-2 flex items-center">
              <FaHome className="h-5 w-5 ml-4" />
              <span className="text-2xl mr-4 mt-1" style={{marginRight:'18%'}}>Dashboard</span>
            </AccordionHeader>
            {/* You can add AccordionBody here if needed */}
          </Accordion>
        </div>
      </nav>
    </aside>
  );
};

export default Sidebar;
