import React, { useState, useRef } from "react";
import './cards.scss';
import { IoCallOutline } from "react-icons/io5";
import { HiOutlineMailOpen } from "react-icons/hi";
// import { Link } from "react-router-dom";

const Card = (props) => {
    const backgroundColor = props.backgroundColor;
    const image = props.image;
    const name = props.name;
    const role = props.role;
    const email = props.email;
    const mobile = props.mobile;
    const idNumber = props.idNumber;
    const subjects = props.subjects ? props.subjects.split(', ') : [];
    const classes = props.classes ? props.classes.split(', ') : [];
    const joiningDate = props.joiningDate;
    const icon_color = props.icon_color;
    const icon_background_color = props.icon_background_color;

    const [showSubjectsPopup, setShowSubjectsPopup] = useState(false);
    const [showClassesPopup, setShowClassesPopup] = useState(false);
    const subjectsRef = useRef(null);
    const classesRef = useRef(null);

    const displaySubjects = subjects.slice(0, 1);
    const displayClasses = classes.slice(0, 2);

    const PopupContent = ({ items }) => (
        <div className="popup-content" style={{
            position: 'absolute',
            backgroundColor: backgroundColor,
            border: `1px solid ${icon_color}`,
            borderRadius: '4px',
            padding: '8px',
            zIndex: 10,
            boxShadow: '0px 0px 10px rgba(0,0,0,0.1)'
        }}>
            {items.map((item, index) => (
                <div key={index}>{item}</div>
            ))}
        </div>
    );

    return (
        <div className="card-container" style={{ background: backgroundColor, boxShadow: `-5px 5px 0px ${icon_color}, 1px 5px 0px ${icon_color}` }}>
            <div style={{ width: "50%", position: 'relative', overflow: 'hidden' }}>
                <div className="zigzag-background" style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    opacity: 0.1,
                    backgroundImage: `linear-gradient(135deg, ${icon_color} 25%, transparent 25%), 
                                      linear-gradient(225deg, ${icon_color} 25%, transparent 25%), 
                                      linear-gradient(45deg, ${icon_color} 25%, transparent 25%), 
                                      linear-gradient(315deg, ${icon_color} 25%, transparent 25%)`,
                    backgroundPosition: '10px 0, 10px 0, 0 0, 0 0',
                    backgroundSize: '20px 20px',
                    backgroundRepeat: 'repeat'
                }}></div>
                <div style={{ height: "60%", display: "flex", alignItems: 'end', justifyContent: 'center', position: 'relative', zIndex: 1 }}>
                    <div className="rounded-full" style={{
                        backgroundColor: icon_color,
                        width: '81px',
                        height: '81px',
                        display: "flex",
                        alignItems: 'center',
                        justifyContent: 'center',
                        overflow: 'hidden'
                    }}>
                        <img src={image} alt="Teacher" style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                        }} />
                    </div>  
                </div> 
               
                <div className="" style={{ height: "40%", display:"flex", flexDirection:'column', alignItems:'center', justifyContent:'center', position: 'relative', zIndex: 1 }}>
                   
                    <div className="">
                        <h2 style={{fontSize:'16px', fontWeight:'600'}}>{name}</h2>
                       <p>{role}</p>        
                    </div> 
                    <div className="flex space-x-4 ">
                        <button className="flex items-center justify-center rounded-full bg-[#50d71e] w-5 h-5" title={mobile} style={{ background: icon_background_color }}>
                            <IoCallOutline className=" text-sm" style={{color:icon_color}}/>
                        </button>                            
                        <button className="flex items-center justify-center rounded-full bg-[#50d71e] w-5 h-5" title={email} style={{ background: icon_background_color }}>
                            <HiOutlineMailOpen className=" text-sm" style={{color:icon_color}}/>      
                        </button>
                    </div>
                <div>
                </div>    
            </div> 
        </div>
            <div className="px-4"style={{ width: "50%", position: "relative" }}>
                        <div className="">
                            <h2>{idNumber || 'N/A'}</h2>
                            <p style={{color:'#939393'}}>Id Number</p>        
                        </div> 
                        <div className="" ref={subjectsRef}>
                            <h2>
                                {displaySubjects.join(', ')|| 'N/A'}
                                {subjects.length > 1 && (
                                    <span 
                                        onMouseEnter={() => setShowSubjectsPopup(true)}
                                        onMouseLeave={() => setShowSubjectsPopup(false)}
                                        style={{cursor: 'pointer', color: icon_color}}
                                    > ...</span>
                                )}
                            </h2>
                            <p style={{color:'#939393'}}>Subjects</p>
                            {showSubjectsPopup && subjects.length > 1 && (
                                <PopupContent items={subjects} />
                            )}
                        </div> 
                        <div className="" ref={classesRef}>
                            <h2>
                                {displayClasses.join(', ')|| 'N/A'}
                                {classes.length > 2 && (
                                    <span 
                                        onMouseEnter={() => setShowClassesPopup(true)}
                                        onMouseLeave={() => setShowClassesPopup(false)}
                                        style={{cursor: 'pointer', color: icon_color}}
                                    > ...</span>
                                )}
                            </h2>
                            <p style={{color:'#939393'}}>Assigned Classes</p>
                            {showClassesPopup && classes.length > 2 && (
                                <PopupContent items={classes} />
                            )}
                        </div> 
                        <div className="">
                            <h2>{joiningDate || 'N/A'}</h2>
                            <p style={{color:'#939393'}}>Date of Joining</p>        
                        </div> 
            </div>
        </div>
    )
}

export default Card;