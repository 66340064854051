import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Progress } from "@material-tailwind/react";
import { Button, ButtonGroup } from "@material-tailwind/react";
import { FaUpload } from "react-icons/fa";

const OverviewSection = ({ overviewData }) => {
  const [selectedFilter, setSelectedFilter] = useState('7days');
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});

  useEffect(() => {
    updateChartData(selectedFilter);
  }, [selectedFilter]);

  const updateChartData = (filter) => {
    // Extract attention_span data for the series
    const newSeries = [
      {
        name: "Attention Span",
        data: overviewData.performanceData[filter].map(item => item.attention_span),
      },
    ];
  
    // Extract date data for the categories
    const newCategories = overviewData.performanceData[filter].map(item => item.date);
  
    setSeries(newSeries);
    setOptions({
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false
        },
        fontFamily: 'Poppins, sans-serif',
        background: '#FBE5DF',
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
          borderRadius: 4,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: newCategories, // Now uses the extracted dates
        labels: {
          style: {
            colors: '#333',
            fontSize: '12px',
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        title: {
          text: 'Attention Span (minutes)',
          style: {
            color: '#333',
            fontSize: '14px',
            fontWeight: 600,
          },
        },
        labels: {
          style: {
            colors: '#333',
            fontSize: '12px',
          },
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: "vertical",
          shadeIntensity: 0.25,
          gradientToColors: ['#BA8585'],
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + ' minutes';
          }
        },
        theme: 'light',
      },
      colors: ['#FF6D6D'],
      grid: {
        borderColor: 'rgba(0,0,0,0.1)',
        strokeDashArray: 5,
      },
      legend: {
        show: false,
      },
    });
  };
  

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  };

  return (
    <div className="bg-white p-5 rounded-3xl shadow-none">
      <div className="flex flex-col lg:flex-row justify-between">
        <div className="bg-gray-200 p-7 rounded-3xl w-full lg:w-6/12 mb-5 mr-5 ml-5 lg:mb-0">
          <label className="mb-3 flex justify-between">
            <span>Class Participation</span>
            <span>{overviewData.classParticipation}%</span>
          </label>
          <Progress className="mb-7" value={overviewData.classParticipation} variant="gradient" color="light-blue" style={{ backgroundColor: '#005975' }} />

          <label className="mb-3 flex justify-between">
            <span>Attendance</span>
            <span>{overviewData.attendance}%</span>
          </label>
          <Progress className="mb-7" value={overviewData.attendance} variant="gradient" color="light-green" style={{ backgroundColor: '#23810C' }} />

          <label className="mb-3 flex justify-between">
            <span>Motivation</span>
            <span>{overviewData.motivation}%</span>
          </label>
          <Progress className="mb-7" value={overviewData.motivation} variant="gradient" color="pink" style={{ backgroundColor: '#F42BA2' }} />
        </div>
        <div className="bg-gray-200 p-7 rounded-3xl w-full lg:w-9/12 ml-5 mr-5">
          <h3 className="text-xl font-bold mb-4">Actions</h3>
          {overviewData.actions.map((action, index) => (
            <div key={index} className={`border-l-4 ${action.actionType === 'urgent' ? 'border-[#5D86EF]' : 'border-[#FCC43E]'} bg-white shadow-md p-4 mb-3 rounded-lg transition-all duration-300 hover:shadow-lg`}>
              <div className="flex justify-between items-center">
                <div className="flex-grow">
                  <p className={`text-lg ${action.actionType === 'urgent' ? 'text-red-600' : 'text-blue-600'} font-semibold`}>{action.text}</p>
                </div>
                {action.uploadButton && (
                  <button className="bg-blue-500 text-white px-4 py-2 rounded-full flex items-center hover:bg-blue-600 transition-colors duration-300">
                       Take Action
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="p-5">
        <div className="bg-[#FBE5DF] p-5 rounded-3xl">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-bold">Attention Span Chart</h3>
            <ButtonGroup className="flex items-center justify-around rounded-full" style={{backgroundColor:'white', height:'41px'}}>
              <Button
                className={`px-4 py-2 rounded-full ${selectedFilter === '7days' ? 'bg-red-800 text-white' : 'bg-white text-gray-500'}`} 
                onClick={() => handleFilterChange('7days')}
                style={{boxShadow:'none'}}
              >
                7 Days
              </Button>
              <Button
                className={`px-4 py-2 rounded-full ${selectedFilter === '30days' ? 'bg-red-800 text-white' : 'bg-white text-gray-500'}`} 
                onClick={() => handleFilterChange('30days')}
                style={{boxShadow:'none',border:'none'}}
              >
                30 Days
              </Button>
              <Button 
                className={`px-4 py-2 rounded-full ${selectedFilter === '12months' ? 'bg-red-800 text-white' : 'bg-white text-gray-500'}`} 
                onClick={() => handleFilterChange('12months')}
                style={{boxShadow:'none',border:'none'}}
              >
                12 Months
              </Button>
            </ButtonGroup>
          </div>
          <ReactApexChart options={options} series={series} type="bar" height={350} />
        </div>
      </div>
    </div>
  );
};

export default OverviewSection;