import React, { useState } from 'react'
import Stepper from './Stepper'
import Form from './Form';
import ParentForm from './ParentForm/ParentForm';
import AcademicDetails from './Academics/Academics';
import DocumentsDetails from './Documents/index'
import StudentForm from './Form1';
import { Alert } from "@material-tailwind/react";



const Index = (props) => {

    const [showfrom1, setshowfrom] = useState(true);
    const [showParent,setParent]=useState(false);
    const [showAcademics,setAcademics]=useState(false);
    const [showDocuments,setDcoments]= useState(false);
    // const [sidebar,setsidebar]=useState(false);
    const [showAlert, setAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");


    const [getparentsStepper,setParentstepper]=useState(false)
    const [getacademicStepper,setacademicsStepper]=useState(false)
    const [getDocumentsStepper,setdocmentsStepper]=useState(false)
    

    function AlertDefault() {   
        return <Alert  onClose={() => setAlert(false)} style={{position: "absolute", top: "50px", right: "0px", width: "50%"}}>{alertMsg}</Alert>;
    }

    const getForm1 = (data) =>{
        setshowfrom(true);
        setParent(false);
        setAcademics(false);
        setDcoments(false);
        setParentstepper()
        setacademicsStepper()
        setdocmentsStepper()
    }

    
    const getParent=(data,id,status)=>{
        setshowfrom(false);
        setParent(true,status);
        setAcademics(false);
        setDcoments(false);
        // setsidebar(status);
        console.log('it is the data of id to Parent',data,id,status)
        setParentstepper("active")
        setacademicsStepper()
        setdocmentsStepper()
    }

    const getAcademics=(data)=>{
        setshowfrom(false);
        setParent(false);
        setAcademics(true);
        setDcoments(false);
        
        setParentstepper("active")
        setacademicsStepper("active")
        setdocmentsStepper()
    }
    
    const getDocuments=(data)=>{
        setshowfrom(false);
        setParent(false);
        setAcademics(false);
        setDcoments(true);
        setParentstepper("active")
        setacademicsStepper("active")
        setdocmentsStepper("active")

    }



    return (
        <div className='m-4'>
            <div style={{
                background: "#ed805f",
                color: "white",
                padding: "12px",
                paddingLeft: "35px",
                borderRadius: "4px",
                fontSize: "20px"
            }}><b>Student Registraion</b>
               
            </div>
            <div style={{ display: "flex" }}>
                <Stepper parentsStepper={getparentsStepper} AcademicsStepper={getacademicStepper} DocumentsStepper={getDocumentsStepper}/>
                {showfrom1 && <StudentForm getParentForm={getParent} setAlert={setAlert} setAlertMsg={setAlertMsg} />}
                {showParent && <ParentForm getStudReg={getForm1} getAcademicsForm={getAcademics} setAlert={setAlert} setAlertMsg={setAlertMsg} />}
                {showAcademics && <AcademicDetails getParentForm={getParent} getDocumentsForm={getDocuments} setAlert={setAlert} setAlertMsg={setAlertMsg} />}
                {showDocuments && <DocumentsDetails getAcademicsForm={getAcademics} setAlert={setAlert} setAlertMsg={setAlertMsg} />}

            </div>
            {showAlert && <AlertDefault />}
        </div>
    )
}

export default Index;