import React from "react";
import './cards.scss';
import Card from './Card';

const Cards = (props) => {
    const cardsData = props?.cardsData;
    const cardBgColors = ["#FFE6DE", "#FBD9F2", "#D9E9FB", "#EBD9FB", "#B5D835", "#EFBE5F"];
    const cardImages = [
        "/images/Student_overview/overview_1.png",
        "/images/Student_overview/overview_2.png",
        "/images/Student_overview/overview_3.png",
        "/images/Student_overview/overview_4.png",
        "/images/Student_overview/overview_5.png",
        "/images/Student_overview/overview_6.png",
    ];

    const sortedCardsData = cardsData
        .filter(card => card.boys > 0 || card.girls > 0) 
        .sort((a, b) => parseInt(b.name) - parseInt(a.name));

    return (
        <div style={{ display: "flex", flexWrap: "wrap", columnGap: "3%", padding: '10px 30px 40px' }}>
            {
                sortedCardsData.map((card, i) => {
                    const bgColorIndex = i % cardBgColors.length;
                    const imageIndex = i % cardImages.length;
                    return (
                        <div key={i} style={{ flex: "0 1 31%" }}>
                            <Card
                                backgroundColor={cardBgColors[bgColorIndex]}
                                image={cardImages[imageIndex]}
                                name={card.name}
                                boys={card.boys}
                                girls={card.girls}
                                sections={card.sections}
                                getSectionList={props.getSectionList}
                            />
                        </div>
                    );
                })
            }
        </div>
    );
};

export default Cards;