import React from 'react';
import './progressBar.scss';

const Index = ({ width = '0', color = 'red', size = 'sm' }) => {
    const barHeight = size === 'sm' ? 'h-1.5' : (size === 'md' ? 'h-2' : size === 'lg' ? 'h-2.5' : 'h-3')
    return (
        <>
            <div
                className={`flex-start flex ${barHeight} w-full overflow-hidden rounded-full
                 bg-blue-gray-50 font-sans text-xs font-medium progress-bar`}>
                <div
                    style={{ width: width, backgroundColor: color }}
                    className="flex h-full w-1/2 items-center justify-center overflow-hidden break-all rounded-full text-white"
                ></div>
            </div>
        </>
    )
}

export default Index;
