import axios from "axios"
import { apiBaseUrl } from "./endpoints";

export const getApiResponse = async (url = '/') => {
    const apiUrl = apiBaseUrl + url;
    console.log(apiUrl);    
    console.log(localStorage);
    try {
        const response = await axios.get(apiUrl, {
            headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` }
        });
        return response; // This should include status, data, etc.
    } catch (error) {
        // Handle and rethrow the error if needed
        throw error; // Rethrow so it can be caught in the calling function
    }
}
