import React, { useState } from 'react';
import './teacherExperience.scss';
import axios from 'axios';
import { apiBaseUrl as baseEndpoint } from '../../../APIs/endpoints';
import { toast, ToastContainer } from 'react-toastify';

const TeacherExpForm = (props) => {

  const defaultData = {
    total_years_of_experience: '',
    subjects: '',
    previous_institution: '',
    major_subject: '',
    id: null,
  };

  const [formData, setFormData] = useState(() => {
    const storedexperiences = localStorage.getItem('experiences');
    return storedexperiences ? JSON.parse(storedexperiences) : defaultData;
  });
  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};
  
    // Convert all form data fields to strings
    const total_years_of_experience = String(formData.total_years_of_experience || '').trim();
    const subjects = String(formData.subjects || '').trim();
    const previous_institution = String(formData.previous_institution || '').trim();
    const major_subject = String(formData.major_subject || '').trim();
  
    // Validate each field
    if (!total_years_of_experience) {
      newErrors.total_years_of_experience = "Total years of experience is required";
      valid = false;
    }
    if (!subjects) {
      newErrors.subjects = "Subjects are required";
      valid = false;
    }
    if (!previous_institution) {
      newErrors.previous_institution = "Previous institution is required";
      valid = false;
    }
    if (!major_subject) {
      newErrors.major_subject = "Major subject is required";
      valid = false;
    }
  
    setErrors(newErrors);
    return valid;
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return; 
    }

    const teacher = JSON.parse(localStorage.getItem('teacher'));
    formData.teacher = teacher.id;
    
    let endpoint = `${baseEndpoint}/api/teachers/${teacher.id}/experiences/`;
    let method = 'POST';
    
    if (localStorage.getItem('experiences')) {
      endpoint = `${baseEndpoint}/api/teachers/${teacher.id}/experiences/${formData.id}/`;
      method = 'PUT';
    }
  
    axios({
      method: method,
      url: endpoint,
      data: formData,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response);
        localStorage.setItem('experiences', JSON.stringify(response.data));
        // props.setAlert(true);
        // props.setAlertMsg('Experience information submitted');
        // props.getDocuments(true);
        toast.success("Teacher information submitted successfully!");

        setTimeout(() => {
          props.getDocuments(true);
        },1000);
      })
      .catch((err) => {
        const errorMessage = err.response && err.response.data 
        ? `Error: ${err.response.status} - ${err.response.statusText}\n${JSON.stringify(err.response.data, null, 2)}`
        : "An unexpected error occurred. Please try again later.";
   toast.error(errorMessage);
      });
  };

  return (
    <div className="w-full flex items-center justify-center" style={{ paddingTop: '2%' }}>
      <div className="w-full max-w-3xl">
        <div className="mb-5 flex items-center justify-between" style={{ marginTop: '2px' }}>
          <h3 className="text-2xl font-bold" style={{ color: '#303972' }}>Experience</h3>
        </div>

        <div className="mb-5">
          <div className="flex justify-between">
            <div className="pr-5 w-1/2">
              <div className="mb-4">
                <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor="total_years_of_experience">
                  Total Years of Experience *
                </label>
                <input
                  type="text"
                  name="total_years_of_experience"
                  value={formData.total_years_of_experience}
                  onChange={handleChange}
                  placeholder="Enter text here"
                  className="w-full h-12 border border-gray-300 rounded px-2"
                />
                {errors.total_years_of_experience && (
                  <p className="text-red-500 text-sm">{errors.total_years_of_experience}</p>
                )}
              </div>
            </div>

            <div className="pl-5 w-1/2">
              <div className="mb-4">
                <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor="subjects">
                  Subjects *
                </label>
                <input
                  type="text"
                  name="subjects"
                  value={formData.subjects}
                  onChange={handleChange}
                  placeholder="Enter text here"
                  className="w-full h-12 border border-gray-300 rounded px-2"
                />
                {errors.subjects && (
                  <p className="text-red-500 text-sm">{errors.subjects}</p>
                )}
              </div>
            </div>
          </div>

          <div className="flex justify-between">
            <div className="pr-5 w-1/2">
              <div className="mb-4">
                <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor="previous_institution">
                  Previous Institution *
                </label>
                <input
                  type="text"
                  name="previous_institution"
                  value={formData.previous_institution}
                  onChange={handleChange}
                  placeholder="Enter text here"
                  className="w-full h-12 border border-gray-300 rounded px-2"
                />
                {errors.previous_institution && (
                  <p className="text-red-500 text-sm">{errors.previous_institution}</p>
                )}
              </div>
            </div>
            <div className="pl-5 w-1/2">
              <div className="mb-4">
                <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor="major_subject">
                  Major Subjects *
                </label>
                <input
                  type="text"
                  name="major_subject"
                  value={formData.major_subject}
                  onChange={handleChange}
                  placeholder="Enter text here"
                  className="w-full h-12 border border-gray-300 rounded px-2"
                />
                {errors.major_subject && (
                  <p className="text-red-500 text-sm">{errors.major_subject}</p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          <button
            type="button"
            className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
            onClick={() => props.getTeacherInfo()}
          >
            Back
          </button>
          <button
            type="button"
            className="bg-[#EF805D] text-white px-4 py-2 rounded"
            onClick={handleSubmit}
          >
            Next
          </button>
        </div>
        <ToastContainer
                          position="top-right"
                          autoClose={5000}
                          hideProgressBar={false}
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                      />
      </div>
    </div>
  );
};

export default TeacherExpForm;
