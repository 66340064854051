import React, { useEffect, useState, useCallback } from 'react';
import './default.scss';
import { Card, CardBody, Button, Avatar, Spinner } from '@material-tailwind/react';
import ProgressBar from '../../commonComp/ProgressBar';
import { useNavigate } from 'react-router-dom';
import { AdjustmentsHorizontalIcon, EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { BiSearch } from 'react-icons/bi';
import { getApiResponse } from '../../APIs/getApiResponse';
import { endpoints } from '../../APIs/endpoints';
import DatePicker from '../../commonComp/DateRangePicker';
import { handleError } from '../../APIs/apiUtils';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Utility function to get the date in mm/dd/yyyy format
const formatDate = (date) => {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
   // return `${month}/${day}/${year}`;
   return `${day}/${month}/${year}`;
};  


// Function to get last week's date range
const getLastWeekDateRange = () => {
    const today = new Date();
    const lastWeekStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 6);
    const lastWeekEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
    return {
        startDate: formatDate(lastWeekStart),
        endDate: formatDate(lastWeekEnd)
    };
};

const Index = () => {
    const navigate = useNavigate();
    const [dashboardData, setDashboardData] = useState({});
    const [dateRange, setDateRange] = useState(getLastWeekDateRange());
    const [isLoading, setIsLoading] = useState(true);

    const getDashboardData = useCallback(async (fromDate, toDate) => {
        setIsLoading(true);
        try {
            if (localStorage.getItem('access_token') === null) {
                navigate("/login");
                return; 
            }

            let urlParams = '';
            if (fromDate) {
                urlParams = `fromDate=${fromDate}`;
            }
            if (toDate) {
                urlParams = urlParams.length ? urlParams + `&toDate=${toDate}` : `toDate=${toDate}`;
            }

            const resp = await getApiResponse(endpoints.DEFAULT_DASHBOARD + '?' + urlParams);

            if (resp.status === 200) {
                setDashboardData(resp.data || {});
            } else {
                if (resp.status === 401) {
                    navigate("/login");
                    return;
                }
                handleError({ response: { status: resp.status } });
            }
        } catch (error) {
            const { errorMessage, statusCode } = handleError(error);
            if (statusCode === 401) {
                navigate("/login");
                return;
            }
            navigate('/error', { 
                state: { errorMessage }
            });
        } finally {
            setIsLoading(false);
        }
    }, [navigate]);

    const getDateRange = useCallback((range) => {
        setDateRange(range);
    }, []);

    useEffect(() => {
        if (dateRange.startDate && dateRange.endDate) {
            getDashboardData(dateRange.startDate, dateRange.endDate);
        }
    }, [dateRange, getDashboardData]);

    const MultiProgressBar = ({ width = [], color = 'red' }) => {
        const progressColors = ['#00BB61', '#42EA5D', '#FFE28F', '#FFA3A3', '#D75252'];
    
        const validWidths = width.filter(w => w !== null && w !== '0%');
    
        return (
            <div style={{ marginBottom: '30px' }}>
                <div className="flex-start flex h-1.5 w-full rounded-full bg-blue-gray-50 font-sans text-xs font-medium">
                    {validWidths.map((w, i) => (
                        <div
                            key={i}
                            style={{ width: w, backgroundColor: progressColors[i] }}
                            className="flex h-full w-1/2 items-center justify-center break-all rounded-full text-white"
                        >
                            {w !== '0%' && (
                                <div style={{ marginTop: '28px', color: '#000', textWrap: 'nowrap', wordBreak: 'keep-all' }}>
                                    {w}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        );
    };
    
    const card = (data, color = 'green') => {
        const { name, tag, participation, motivation, attention_span, attendance, emotional_state } = data;
        const safeAttentionSpan = attention_span || '0%';
        const safeMotivation = motivation || '0%';
        
        // Skip rendering the card if attendance is '0/0'
        if (attendance === '0/0') {
            return null;
        }
        
        return (
            <div className='class-info-card' onClick={() => navigate(`/behaviour/${data.name}`,
             { state:
              { fromDate: dateRange.startDate,
               toDate: dateRange.endDate 
               } 
            })}>
            <Card style={{ border: `1px solid ${color}` }}>
                <CardBody>
                    <div className='class-header'>
                        <div className='class-number' style={{ background: color }}>{name}</div>
   
                    </div>
                    <div className='class-progress-details'>
                        <div className='progress-bar-section'>
                            <div className='progress-title'>
                                <span>Attention Span</span>
                                <span>{safeAttentionSpan}</span>
                            </div>
                            <ProgressBar width={safeAttentionSpan} color={color} />
                        </div>
                        <div className='progress-bar-section'>
                            <div className='progress-title'>
                                <span>Motivation & Initiation</span>
                                <span>{safeMotivation}</span>
                            </div>
                            <ProgressBar width={safeMotivation} color={color} />
                        </div>
                        <div className='progress-bar-section'>
                            <div className='progress-title'>
                                <span>Emotional State</span>
                                <span className='emoji-icons'>
                                    <span>😊</span>
                                    <span>-</span>
                                    <span>😡</span>
                                </span>
                            </div>
                            <MultiProgressBar width={emotional_state} />
                        </div>
                    </div>
                    <div className='card-footer-content'>
                        <div className='card-footer-info'>
                            <div>{attendance}</div>
                            <div>Attendance</div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
        );
    };

    const colors = ['#FF9595', '#AEBD00', '#C62CFD', '#FF9595', '#1808CB', '#9CC0F5'];
    return (
        <div className='default-dashboard-container'>
            <ToastContainer />
            <div className='dashboard-filters'>
                <div className='search-date'>
                <DatePicker 
                        getDateRange={getDateRange} 
                        initialDateRange={dateRange}
                        initiallySelected={true}
                    />
                </div>
            </div>
            {isLoading ? (
                <div className="flex justify-center items-start h-screen">
                    <Spinner className="h-12 w-12" color="blue" />
                </div>
            ) : (
                <div className='default-content-section'>
                    {/* Default Dashboard content */}
                    <div className='default-left-section'>
                        <h3 className='section-title'>All Classes</h3>
                        <div className='cards-list'>
                            {
                                dashboardData?.classes?.map((cardObj, i) => {
                                    return card(cardObj, colors[i]);
                                }).filter(Boolean)
                            }
                        </div>
                    </div>
 
                </div>
            )}
        </div>
    )
}

export default Index;