import { HttpStatusCode } from "axios"

export const apiBaseUrl =
 // "http://192.168.1.138:8000";
   //"http://127.0.0.1:8000";
  //"https://neep.transpoze.ai";
    "https://neep-backend.transpoze.ai"


export const endpoints = {
    OVERVIEW: "/api/overview",
    STUDENT_DETAILS: "/api/studentdetails/",
    STUDENTS_LIST: "/api/list-students",
    ATTENDANCE: "/api/get-attendance",
    DEFAULT_DASHBOARD: "/api/behaviour-dashboard",
    BEHAVIOURAL: "/api/behaviour-class-dashboard",
    TEACHEROVERVIEW:"/api/teachers-overview",
    STUDENTSBEHAVIOUR: "/api/student-summary/",
    STUDENT_PROFILE: "/api/student-profile/",
    SUBJECT_LIST:"/api/subjects",
    GRADES: "/api/classes"
}