import React, { useState, useEffect } from 'react';
import { Switch } from "@material-tailwind/react";
import axios from 'axios';
import { apiBaseUrl as baseEndpoint } from '../../../APIs/endpoints';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate , useLocation} from 'react-router-dom';

const AcademicDetails = (props) => {
    function backtoparent() {
        props.getParentForm(true);
    }

    var Academic_Data = {
        rfid_info: '',
        sid: "",
        year: '',
        board: '',
        medium: '',
        section: '',
        grade: '',
        first_language: '',
        second_language: '',
        scholarship: false,
        previous_school: '',
        id: null,
        student: null,
    };

    const [formData, setFormData] = useState(!localStorage.getItem("academic_Details") ? Academic_Data : JSON.parse(localStorage.getItem("academic_Details")));
    const [errors, setErrors] = useState({});

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const token = localStorage.getItem('access_token'); 
    
        if (!token) { 
            navigate('/login'); 
        }
    
        const editPath = '/students/edit';
    
        // Clear localStorage when navigating away from the edit page
        if (!location.pathname.startsWith(editPath)) {
            // localStorage.removeItem('academic_Details');
            // localStorage.removeItem('stud_info');
            // localStorage.removeItem('parent_info');
            // localStorage.removeItem('documents');
        }
    }, [navigate, location.pathname]);
    

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleScholarship = (isChecked) => {
        // Update your formData state with the new value
        setFormData(prevFormData => ({
            ...prevFormData,
            scholarship: isChecked
        }));
    };
    
    const updateField = (field) => (event) => {
        setFormData({ ...formData, [field]: event.target.value });
    };

    const getRandomThreeDigitNumber = () => {
        return Math.floor(Math.random() * (999 - 100 + 1)) + 100;
    };

    const validateForm = () => {
        const newErrors = {};
        // if (!formData.rfid_info) newErrors.rfid_info = "RFID is required";
        // if (!formData.board) newErrors.board = "Board is required";
         if (!formData.grade) newErrors.grade = "Grade is required";
        // if (!formData.first_language) newErrors.first_language = "First Language is required";
        // if (!formData.second_language) newErrors.second_language = "Second Language is required";
        // if (!formData.year) newErrors.year = "Academic Year is required";
        // if (!formData.medium) newErrors.medium = "Medium is required";
         if (!formData.section) newErrors.section = "Section is required";
        // if (!formData.previous_school) newErrors.previous_school = "Previous School is required";
        return newErrors;
    };

    function handleSubmit(event) {
        event.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        var student = JSON.parse(localStorage.getItem("stud_info"));
        formData.student = student.id;
        formData.sid = getRandomThreeDigitNumber();
        var endpoint = `${baseEndpoint}/api/students/${student.id}/academics/`;
        var method = 'POST';

        if (localStorage.getItem("academic_Details")) {
            endpoint = `${baseEndpoint}/api/students/${student.id}/academics/${formData.id}`;
            method = 'PUT';
        }

        axios({
            method: method,
            url: endpoint,
            data: formData,
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('access_token')}`
            }
        })
        .then(response => {
            localStorage.setItem('academic_Details', JSON.stringify(response.data));
            toast.success("Academic Information submitted successfully!");
    
            // Delay navigation to ensure toast message is displayed
            setTimeout(() => {
                props.getDocumentsForm(true);
            }, 1000); // Adjust the delay as needed
        })
        .catch(err => {
            const errorMessage = err.response && err.response.data 
                ? `Error: ${err.response.status} - ${err.response.statusText}\n${JSON.stringify(err.response.data, null, 2)}`
                : "An unexpected error occurred. Please try again later.";
            toast.error(errorMessage);
        });
    }

    function saveAndExit(event) {
        event.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        var student = JSON.parse(localStorage.getItem("stud_info"));
        formData.student = student.id;
        formData.sid = getRandomThreeDigitNumber();
        var endpoint = `${baseEndpoint}/api/students/${student.id}/academics/`;
        var method = 'POST';

        if (localStorage.getItem("academic_Details")) {
            endpoint = `${baseEndpoint}/api/students/${student.id}/academics/${formData.id}`;
            method = 'PUT';
        }

        axios({
            method: method,
            url: endpoint,
            data: formData,
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('access_token')}`
            }
        })
        .then(response => {
            localStorage.setItem('academic_Details', JSON.stringify(response.data));
            toast.success("Academic Information submitted successfully!");
    
            setTimeout(() => {
                localStorage.removeItem('academic_Details');
                localStorage.removeItem('stud_info');
                localStorage.removeItem('parent_info');
                localStorage.removeItem('documents');
                navigate(-1);
            }, 1000);
        })
        .catch(err => {
            const errorMessage = err.response && err.response.data 
                ? `Error: ${err.response.status} - ${err.response.statusText}\n${JSON.stringify(err.response.data, null, 2)}`
                : "An unexpected error occurred. Please try again later.";
            toast.error(errorMessage);
        });

    }

    return (
        <div className="w-full flex items-center justify-center">
            <form onSubmit={handleSubmit} className="w-full max-w-3xl">
                <div className="mb-5 flex items-center justify-between">
                <h3 className="font-poppins text-2xl font-bold leading-9 text-left text-[#303972]">
                    {localStorage.getItem('academic_Details') ? 'Update Academic Details' : 'Academic Details'}
                    </h3>
                </div>
                <div className="mb-5 flex justify-between">
                    <div className="pr-5 w-1/2">
                        <div className="mb-4">
                            <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor="rfid">RFID</label>
                            <input 
                                type="text" 
                                id="rfid" 
                                name='rfid_info'
                                placeholder="Enter text here" 
                                onChange={handleChange}
                                value={formData.rfid_info}
                                className="w-full h-12 border border-gray-300 rounded px-2" 
                            />
                            {/* {errors.rfid_info && <p className="text-red-500 text-sm mt-1">{errors.rfid_info}</p>} */}

                        </div>
                        <div className="mb-4">
                            <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor="board">Board</label>
                            <select
                                name='board'
                                onChange={updateField('board')}
                                value={formData.board}
                                className="w-full h-12 bg-white border border-gray-300 rounded px-2 "
                            >
                                <option value="">Select</option>
                                <option value="ib">IB</option>
                                <option value="icse">ICSE</option>
                                <option value="cbse">CBSE</option>
                                <option value="state-board">STATE BOARD</option>
                            </select>
                            {/* {errors.board && <p className="text-red-500 text-sm mt-1">{errors.board}</p>}                             */}
                        </div>
                        <div className="mb-4">
                            <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor="grade">Class*</label>
                            <select
                                name="grade"
                                onChange={updateField('grade')}
                                value={formData.grade}
                                className="w-full h-12 bg-white border border-gray-300 rounded px-2 "
                            >
                                <option value="">Select</option>
                                <option value="P">Play School</option>
                                <option value="Nurs">Nursery</option>
                                <option value="LKG">LKG</option>
                                <option value="UKG">UKG</option>
                                <option value="1">I Grade</option>
                                <option value="2">II Grade</option>
                                <option value="3">III Grade</option>
                                <option value="4">IV Grade</option>
                                <option value="5">V Grade</option>
                                <option value="6">VI Grade</option>
                                <option value="7">VII Grade</option>
                                <option value="8">VIII</option>
                                <option value="9">IX</option>
                                <option value="10">X</option>
                            </select>
                            {errors.grade && <p className="text-red-500 text-sm mt-1">{errors.grade}</p>}                           
                        </div>
                        <div className="mb-4">
                            <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor="second_language">First Language</label>
                            <select
                                name='first_language'
                                onChange={updateField('first_language')}
                                value={formData.first_language}
                                className="w-full h-12 bg-white border border-gray-300 rounded px-2 "
                            >
                                <option value="">Select</option>
                                <option value="Telugu">Telugu</option>
                                <option value="English">English</option>
                            </select>
                            {/* {errors.first_language && <p className="text-red-500 text-sm mt-1">{errors.first_language}</p>}                            */}
                        </div>
                        <div className="mb-3">
                            <label className="font-poppins text-lg font-semibold text-[#303972] ">
                                Scholarship
                            </label>
                            <input
                                type="checkbox"
                                name="scholarship"
                                checked={formData.scholarship}
                                onChange={(e) => handleScholarship(e.target.checked)}
                                className="ml-4 mt-3"
                            />
                        </div>
                    </div>
                    <div className="pl-5 w-1/2">
                        <div className="mb-4">
                            <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor="year">Academic Year</label>
                            <select
                                name='year'
                                onChange={updateField('year')}
                                value={formData.year}
                                className="w-full h-12 bg-white border border-gray-300 rounded px-2 "
                            >
                                <option value="">Select</option>
                                <option value="2021-2022">2021-2022</option>
                                <option value="2022-2023">2022-2023</option>
                                <option value="2023-2024">2023-2024</option>
                                <option value="2024-2025">2024-2025</option>
                            </select>
                            {/* {errors.year && <p className="text-red-500 text-sm mt-1">{errors.year}</p>}                            */}
                        </div>
                        <div className="mb-4">
                            <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor="medium">Medium</label>
                            <select
                                name='medium'
                                onChange={updateField('medium')}
                                value={formData.medium}
                                className="w-full h-12 bg-white border border-gray-300 rounded px-2 "
                            >
                                <option value="">Select</option>
                                <option value="Telugu">Telugu</option>
                                <option value="English">English</option>
                            </select>
                            {/* {errors.medium && <p className="text-red-500 text-sm mt-1">{errors.medium}</p>}                           */}
                        </div>
                        <div className="mb-4">
                            <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor="section">Section*</label>
                            <select
                                name='section'
                                onChange={updateField('section')}
                                value={formData.section}
                                className="w-full h-12 bg-white border border-gray-300 rounded px-2 "
                            >
                                <option value="">Select</option>
                                <option value="A">A</option>
                                <option value="B">B</option>
                                <option value="C">C</option>
                                <option value="D">D</option>
                                <option value="E">E</option>
                                <option value="F">F</option>
                                <option value="G">G</option>
                                <option value="H">H</option>
                                <option value="I">I</option>
                                <option value="J">J</option>
                                <option value="K">K</option>
                                <option value="L">L</option>
                                <option value="M">M</option>
                                <option value="N">N</option>
                                <option value="O">O</option>
                                <option value="P">P</option>
                                <option value="Q">Q</option>
                                <option value="R">R</option>
                                <option value="S">S</option>
                                <option value="T">T</option>
                                <option value="U">U</option>
                                <option value="V">V</option>
                                <option value="W">W</option>
                                <option value="X">X</option>
                                <option value="Y">Y</option>
                                <option value="Z">Z</option>
                            </select>
                            {errors.section && <p className="text-red-500 text-sm mt-1">{errors.section}</p>}                           
                        </div>
                        <div className="mb-4">
                            <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor="second_language">Second Language</label>
                            <select
                                name='second_language'
                                onChange={updateField('second_language')}
                                value={formData.second_language}
                                className="w-full h-12 bg-white border border-gray-300 rounded px-2 "
                            >
                                <option value="">Select</option>
                                <option value="Telugu">Telugu</option>
                                <option value="English">English</option>
                            </select>
                            {/* {errors.second_language && <p className="text-red-500 text-sm mt-1">{errors.second_language}</p>}                            */}
                        </div>
                        <div className="mb-4">
                            <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor="previous_school">Previous School</label>
                            <input 
                                type="text" 
                                id="previous_school" 
                                name='previous_school'
                                placeholder="Enter text here" 
                                onChange={handleChange}
                                value={formData.previous_school}
                                className="w-full h-12 border border-gray-300 rounded px-2"
                            />
                             {/* {errors.previous_school && <p className="text-red-500 text-sm mt-1">{errors.previous_school}</p>}                            */}
                        </div>
                    </div>
                </div>
                <div className='flex justify-end'>
                    <div className="button-container">
                        <button 
                            type="button" 
                            className="bg-gray-300 text-black px-4 py-2 rounded mr-2 button" 
                            onClick={backtoparent}
                        >
                            Back
                        </button>
                        {/* <button 
                            type="submit" 
                            className="bg-blue-600 text-white px-4 py-2 rounded button-active" 
                        >
                            {formData.id ? 'Update' : 'Next'}
                        </button> */}
                        {localStorage.getItem('academic_Details') ? (
                            <>
                                <button type="submit"  className="bg-blue-600 text-white px-4 py-2 rounded button-active">Update</button>
                                <button type="button" onClick={saveAndExit} className="bg-blue-600 text-white px-4 py-2 rounded button-active">Save and Exit</button>
                            </>
                        ) : (
                            <>
                                <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded button-active">Next</button>
                            </>
                        )}
                    </div>
                </div>
            </form>
             <div>
                {/* Other components */}
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />

            </div>
        </div>
    );
}

export default AcademicDetails;
