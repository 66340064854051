import React from "react";
import './cards.scss';
import Card from './Card';
import { apiBaseUrl } from "../../../../APIs/endpoints";

const Cards = (props) => {
    const { cardsData } = props;
    const cardBgColors = ["#F5F1FF", '#FFF5F1', "#FFE3B3", "#CDF3FF", "#FFF1F4", "#F1FEFF"];
    const icon_color = ['#C9B5FF','#FFA682','#FFC45E','#2B82D4','#FF93AA','#76AFB3'];
    const icon_background_color = ['#C9B5FF40','#FFA68240','#FFC45E40','#2B82D440','#FF93AA40','#76AFB340'];

    
    return (
        <div 
            style={{ 
                display: "grid", // Use grid layout
                gridTemplateColumns: "repeat(4, 1fr)", // Four cards per row
                gap: "20px", // Space between cards
                padding: '10px 30px 40px' 
            }}
        >
            {
                cardsData.map((card, i) => (
                    <div key={card.idNumber}>
                        <Card
                            backgroundColor={cardBgColors[i % cardBgColors.length]}
                            image={`${apiBaseUrl}${card.teacher_photo}`}
                            name={card.name}
                            icon_color={icon_color[i % icon_color.length]}
                            icon_background_color={icon_background_color[i % icon_background_color.length]}
                            idNumber={card.id_no}
                            subjects={card.subjects}
                            classes={card.classes}
                            joiningDate={card.date_of_joining}
                            role={card.role}
                            email={card.email}
                            mobile={card.mobile_no}
                        />
                    </div>
                ))
            }
        </div>
    );
}

export default Cards;