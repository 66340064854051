import React, { useEffect, useState } from "react";
import Filter from "./Filter";
import Cards from "./Cards";
import { Breadcrumbs } from "@material-tailwind/react";
import { getApiResponse } from "../../../APIs/getApiResponse";
import { endpoints } from "../../../APIs/endpoints";
import { handleError } from "../../../APIs/apiUtils";
import { useNavigate } from "react-router-dom";

function Overview() {
    const [showList, setShowList] = useState(false);
    const [filtersObj, setFiltersObj] = useState({});
    const [cardsData, setCardsData] = useState([]);
    const [classesList, setClassesList] = useState([]);
    const [sectionList, setSectionList] = useState([]);
    const [selectedClassValue, setselectedClassValue] = useState('');
    const [selectedSectionValue, setSelectedSectionValue] = useState('');
    const [clearFilters, setClearFilters] = useState(false);
    const [error, setError] = useState(null); 
    // const [schoolId, setSchoolId] = useState(1); // Added schoolId state

    const navigate = useNavigate();

    const getOverviewData = async () => {
        try {
            const resp = await getApiResponse(endpoints.TEACHEROVERVIEW);
            
            if (resp.status === 200) {
                setCardsData(resp.data);
    
                if (resp.data.length) {
                    const classes = [];
                    const classSections = {};
                    resp.data.forEach(item => {
                        if (!classes.includes(item.classes)) {
                            classes.push(item.classes);
                            classSections[item.classes] = item.sections;
                        }
                    });
                    setClassesList(classes);
                    setSectionList(classSections);
                }
            } else {
                setCardsData([]);
                if (resp.status === 401) {
                    navigate("/login"); // Redirect to login
                }
                setError(handleError({ response: { status: resp.status } }));
            }
        } catch (error) {
            const { errorMessage, statusCode } = handleError(error);
            navigate('/error', { 
                state: { errorMessage }
            });
        }   
    };
    
    
    useEffect(() => {
        getOverviewData();
    }, []);

    const getSectionList = (data) => {
        setShowList(true);
        setselectedClassValue(data?.name);
        setSelectedSectionValue(data?.section);
    };

    const getClassSelection = val => {
        const sections = sectionList[val] || [];
        setSectionList(sections);
    };

    const getFilterInputs = data => {
        setFiltersObj(data);
        setClearFilters(false);
    };

    useEffect(() => {
        console.log(filtersObj);
    }, [filtersObj]);

    return (
        <div>
            <Breadcrumbs>
                <div onClick={() => {
                    setShowList(false); 
                    setClearFilters(true);
                    setSelectedSectionValue(null);
                    setselectedClassValue(null);
                    }}>Teachers</div>
                {showList && <div>List</div>}
            </Breadcrumbs>
            <Filter 
                classesList={classesList}
                selectedClassValue={selectedClassValue}
                sectionsList={sectionList}
                selectedSectionValue={selectedSectionValue}
                getClassSelection={getClassSelection}
                getFilterInputs={getFilterInputs}
                clearFilters={clearFilters} 
            />
            {error ? (
                <div className="text-red-500 text-center">{error}</div>
            ) : !cardsData.length ? (
                <div className="text-gray-500 text-center">No data available.</div>
            ) : (
                !showList && <Cards cardsData={cardsData} getSectionList={getSectionList} />
            )}
        </div>
    );
}

export default Overview;
