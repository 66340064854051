import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { apiBaseUrl  } from '../APIs/endpoints';

const LoginPage = () => {
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  //const [schoolName, setSchoolName] = useState('');

  const submit = async (e) => {
    e.preventDefault();
    const user = {
      username: username.toLowerCase(),
      password: password
    };

    try {
      // Create the POST request
      const { data } = await axios.post(
        apiBaseUrl + '/token/',
        user,
        { headers: { 'Content-Type': 'application/json' } },
        { withCredentials: true }
      );

      console.log(data,"jhkdjcbkjnx"); // Check the structure of the response

      // Initialize the access & refresh tokens in local storage.
      localStorage.setItem('access_token', data.access);
      localStorage.setItem('refresh_token', data.refresh);
      localStorage.setItem('user', username.toLowerCase());
      axios.defaults.headers.common['Authorization'] = `Bearer ${data['access']}`;

      const schoolResponse = await axios.get(`${apiBaseUrl}/api/get_school_name/`, {
        headers: { 'Authorization': `Bearer ${data.access}` } 
    });
    localStorage.setItem('school_name', schoolResponse.data.school);
    const subjectsResponse = await fetchSubjects(schoolResponse.data.school);
    localStorage.setItem('subjects', JSON.stringify(subjectsResponse.data.subjects)); // Store subjects in local storage

       window.location.href = '/';
    } catch (error) {
      // Update the errorMessage state with the error response
      setErrorMessage(error.response?.data?.detail || 'Login failed. Please try again.');
    }
  };

  const fetchSubjects = async (schoolName) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/subjects?school=${encodeURIComponent(schoolName)}`);
      console.log('Subjects:', response.data.subjects); // Handle the subjects as needed
      // You can store subjects in state or context if needed
      return response; // Return the response to store in local storage
    } catch (error) {
      console.error('Failed to fetch subjects:', error);
      return null; // Return null if fetching subjects fails
    }
  };

  const handleUsernameChange = (e) => {
    setUserName(e.target.value);
  };

  return (
    <div fluid className="min-vh-100 d-flex align-items-center justify-content-center" style={{ width: '100%' }}>
      <div className="flex w-full">
        <div className="flex flex-col items-center justify-center bg-[#F9EBFF] md:w-1/2 h-screen" style={{ maxWidth: '50%', margin: '20px' }}>
          <div className="text-center mb-4 ml-1 mt-1 relative">
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="w-40 h-40 bg-[#FFDCC9] rounded-full" style={{ transform: 'translateX(-120%)' }}></div>
            </div>
            <h1 className="relative text-[#EF805D] text-5xl font-bold" style={{ fontSize: '54px', fontWeight: '700' }}>
              Transpoze NEEP
            </h1>
          </div>
          <img src="/images/login.png" alt="School Illustration" className="img-fluid" />
        </div>
        <div className="flex items-center justify-center md:w-1/2">
          <div className="p-4" style={{ width: '80%', maxWidth: '400px', border: 'none' }}>
            <div className="p-4">
              {/* Conditionally render the error message */}
              {errorMessage && (
                <div className="mb-4 text-red-600 font-bold">
                  {errorMessage}
                </div>
              )}
              <h2 className="mb-4 font-bold text-2xl text-[#EF805D]">Login</h2>
              <form onSubmit={submit}>
                <div className="mb-3">
                  <input type="text" value={username} onChange={handleUsernameChange} placeholder="User Id" className="w-full p-4 rounded-lg bg-[#F5F5F7] h-16" style={{ borderRadius: '10px', width: '330px' }} />
                </div>
                <div className="mb-3">
                  <div className="flex">
                    <input type={showPassword ? 'text' : 'password'} value={password} onChange={e=>setPassword(e.target.value)} placeholder="Password" className="w-full p-4 rounded-l-lg bg-[#F5F5F7] h-16" style={{ borderRadius: '10px 0px 0px 10px' }} />
                    <span onClick={() => setShowPassword(!showPassword)} className="flex items-center px-3 bg-gray-200 cursor-pointer rounded-r-lg" style={{ borderRadius: '0px 10px 10px 0px' }}>
                      {showPassword ? <FaEye /> : <FaEyeSlash />}
                    </span>
                  </div>
                </div>
                <div className="mb-3">
                  <br />
                </div>
                <button type="submit" className="w-full bg-[#EF805D] border-none h-14 rounded-lg text-white font-bold text-base" style={{ borderRadius: '8px', width: '330px' }}>
                  Login
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
