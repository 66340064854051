import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import axios from 'axios';
import { FiPlusCircle } from "react-icons/fi";
import { apiBaseUrl } from '../../APIs/endpoints';
import EditUserPopup from './EditUserPopup'; 

const UserDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [school, setSchool] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false); 
  const defaultImage = '/images/School Building.png';

  useEffect(() => {
    axios.get(`${apiBaseUrl}/api/schools/${id}/`)
      .then(response => {
        setSchool(response.data);
      })
      .catch(error => {
        console.error('Error fetching school details:', error);
      });
  }, [id]);

  const handleAddUserClick = () => {
    navigate(`/add-user/${id}`);
  };

  const handleEditSchool = () => {
    localStorage.setItem('school_info', JSON.stringify(school));
    navigate(`/edit-school/${id}`);
  };

  const handleEditUserClick = (user) => {
    setSelectedUser(user);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setSelectedUser(null);
  };

  if (!school) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-4 h-full">
      <div className="flex justify-between items-center mb-6" style={{paddingLeft:'10%', paddingRight:'19.5%'}}>
        <h1 className="font-bold text-3xl">{school.name}</h1>
        <button
          onClick={handleAddUserClick}
          className="flex items-center px-4 py-2 text-orange-500 border border-orange-500 rounded shadow-none"
          style={{ 
            marginRight: '8px', 
            width: '161px', 
            height: '50px', 
            fontSize: "16px",
            fontWeight:'500',
            backgroundColor: 'transparent',
          }}
        >
          <FiPlusCircle /> &nbsp;&nbsp;Add User
        </button>
      </div>
      <div className="w-[70%] ml-[10%] mt-[2%] h-full" style={{height:'100%',border: '1px solid #C8C8C8'}}>
        <div className="w-[80%] ml-[10%] mt-[5%]  border-none mt-5">
          <div className="p-4">
            <div className="flex items-center justify-between">
              <div className="auto">
                <div className="rounded-full bg-[#FFD8D8] flex items-center justify-center" style={{ width: '80px', height: '80px' }}>
                  <img
                    src={school.logo ? school.logo : defaultImage} 
                    alt={school.name}
                    className="rounded-full w-20 h-20"
                  />
                </div>
              </div>  
              <div className="ml-4">
                <h6 style={{fontSize:'12px',fontWeight:'400',color:'#8A8A8A'}}>Name</h6>
                <p style={{fontSize:'18px',fontWeight:'400'}}>{school.name}</p>
                <h6 style={{fontSize:'12px',fontWeight:'400',color:'#8A8A8A'}}>Code</h6>
                <p style={{fontSize:'18px',fontWeight:'400'}}>{school.school_code}</p>
              </div>
              <div className="ml-5" style={{marginBottom:'3%', marginLeft:'20%'}}>
                <h6 style={{fontSize:'12px',fontWeight:'400',color:'#8A8A8A'}}>Address</h6>
                <p style={{fontSize:'18px',fontWeight:'400',width:'256px'}}>{school.address}</p>
              </div>
              <div className="rounded-sm" style={{ marginTop: '-60px', marginRight:'5%' }}>
                <button
                  className="border border-black text-black flex items-center justify-center h-3 w-16 rounded"
                  onClick={handleEditSchool}
                  style={{ borderColor: '#000000', color: '#000000',display:'flex',alignItems:'center',justifyContent:'center', height:'32px', width:'70px' }}
                >
                  <FaEdit /> Edit
                </button>
              </div>
            </div>
          </div>
          <div className="p-4">
            <h3 style={{fontSize:'24px', fontWeight:'500'}}>User Details</h3>
            <table className="w-full text-center" style={{
              marginTop: '2%',
              border: '2px solid #B1B1B1', // Border around the entire table
              borderCollapse: 'collapse',   // Ensure borders are collapsed
            }}>
              <thead>
                <tr style={{ backgroundColor: '#f0f0f0' }}> {/* Grey background color for header */}
                  <th className="border px-4 py-2" style={{ border: '1px solid #B1B1B1' }}>Name</th>
                  <th className="border px-4 py-2" style={{ border: '1px solid #B1B1B1' }}>Mobile Number</th>
                  <th className="border px-4 py-2" style={{ border: '1px solid #B1B1B1' }}>Email</th>
                  <th className="border px-4 py-2" style={{ border: '1px solid #B1B1B1' }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {school.school_users.map((user) => (
                  <tr key={user.id}>
                    <td className="border px-4 py-2" style={{ border: '1px solid #B1B1B1' }}>{user.name}</td>
                    <td className="border px-4 py-2" style={{ border: '1px solid #B1B1B1' }}>{user.mobile}</td>
                    <td className="border px-4 py-2" style={{ border: '1px solid #B1B1B1' }}>{user.email}</td>
                    <td className="border px-4 py-2" style={{ border: '1px solid #B1B1B1' }}>
                      <button
                        className="text-bg-orange-500"
                        onClick={() => handleEditUserClick(user)}
                      >
                        <FaEdit />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>   
      {isPopupOpen && (
        <EditUserPopup 
          user={selectedUser} 
          onClose={handleClosePopup} 
        />
      )}

    </div>
  );
};

export default UserDetail;