import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; 
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiBaseUrl } from '../../APIs/endpoints';

const EditUserPopup = ({ user = {}, onClose }) => {
  const [formData, setFormData] = useState({
    name: user.name || '',
    mobile: user.mobile || '',
    email: user.email || '',
    profile_type: user.profile_type || '',
    password: '',
  });

  const [passwordError, setPasswordError] = useState(''); // State for password error

  const navigate = useNavigate(); 

  useEffect(() => {
    if (user) {
      setFormData({
        name: user.name || '',
        mobile: user.mobile || '',
        email: user.email || '',
        profile_type: user.profile_type || '',
        password: '',
      });
    }
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Clear password error when the user starts typing
    if (name === 'password') {
      setPasswordError('');
    }
  };

  const validatePassword = (password) => {
    if (password.length < 8) {
      return "Password must be at least 8 characters long.";
    }
    return '';
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const passwordValidationMessage = validatePassword(formData.password);
    if (passwordValidationMessage) {
      setPasswordError(passwordValidationMessage);
      return;
    }

    if (!user.email) {
      toast.error('User email is not available');
      return;
    }

    const updateData = {
      ...formData,
      user: {
        first_name: formData.name,
        email: formData.email,
        password: formData.password,
      },
    };

    axios.put(`${apiBaseUrl}/api/profiles/${user.id}/`, updateData)
      .then(response => {
        toast.success('User details updated successfully!');
        onClose();

        // Optionally refresh the page
        window.location.reload();
      })
      .catch(error => {
        toast.error('Error updating user details:', error);
      });
  };

  return (
    <>
      <ToastContainer />
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white p-8 rounded-lg w-[600px] mt-10">
          <h2 className="text-[#303972] text-2xl font-bold font-poppins mb-4">Edit User Details</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="text-[#303972] text-lg font-semibold font-poppins">Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full border px-3 py-2 rounded"
              />
            </div>
            <div className="mb-4">
              <label className="text-[#303972] text-lg font-semibold font-poppins">Mobile</label>
              <input
                type="text"
                name="mobile"
                value={formData.mobile}
                onChange={handleInputChange}
                className="w-full border px-3 py-2 rounded"
              />
            </div>
            <div className="mb-4">
              <label className="text-[#303972] text-lg font-semibold font-poppins">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                readOnly
                className="w-full border px-3 py-2 rounded bg-gray-300"
              />
            </div>
            <div className="mb-4">
              <label className="text-[#303972] text-lg font-semibold font-poppins">New Password</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                className={`w-full border px-3 py-2 rounded ${passwordError ? 'border-red-500' : ''}`}
              />
              {passwordError && (
                <p className="text-red-500 text-sm mt-1">{passwordError}</p>
              )}
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                className="mr-4 px-4 py-2 bg-gray-300 rounded"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-[#EF805D] text-white rounded"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditUserPopup;
