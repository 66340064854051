import React from "react";
import './stepper.scss';

const Stepper = (props) => {

    return (
        <div className="sis-form-stepper-container" style={{backgroundColor:'#feede5', height:'100%'}}>
            <ul className="stu" >
                <li className="stu1 active">
                    <div className="step-count">1</div>
                    <div className="step-name">Student Details</div>
                </li>
                <li className={`stu1 ${props.parentsStepper}`}>
                    <div className="step-count">2</div>
                    <div className="step-name">Parent Details</div>
                </li>
                <li className={`stu1 ${props.AcademicsStepper}`}>
                    <div className="step-count">3</div>
                    <div className="step-name">Academics</div>
                </li>
                <li className={`stu1 ${props.DocumentsStepper}`}>
                    <div className="step-count">4</div>
                    <div className="step-name">Documents</div>
                </li>
                <img 
                className="stepper-image"
                src="/images/stepper_image.png"
                alt="nature image"
                style={{marginBottom:'20px' , width:'100%', backgroundColor:'#feede5', paddingLeft:"10%"}}
            />
            </ul  >

        </div>
    )
}

export default Stepper;