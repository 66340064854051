
// Import necessary packages and components
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import AdminBar from "./AdminBar/AdminBar";
import Menu from "./Menu";
import { FaBars, FaArrowLeft } from "react-icons/fa"; 
import DefaultDashboard from "./Dashboard/Default";
import BehaviourDashboard from "./Dashboard/Behaviour";
import Overview from "./Sis/Overview/StudentsOverview";
import Admission from "./Sis/Admission";
import ErrorPage from "./ErrorPage/ErrorPage";
import TeachersAdmission from "./Tis/TeachersAdmission";
import SchoolDashboard from "./School-Registration/School/Dashboard";
import UserDetail from "./School-Registration/School/Schools";
import AddSchool from "./School-Registration/School/AddSchool";
import AddUser from "./School-Registration/School/AddUser";
import LoginPage from "./Login/login";
import LoginPage1 from "./Login/login1";
import Logout from "./Login/logout";
import Header from "./School-Registration/Header/Header";
import Sidebar from "./School-Registration/Sidebar/Sidebar";
import TeacherOverview from "./Tis/Overview/TeachersOverview";
import StudentProfile from "./Sis/Overview/StudentProfile/studentProfile";
import Attendance from "./Sis/Attendance";
import DefaultTable from "./Dashboard/studentBehaviours";
import EditSchool from "./School-Registration/School/EditSchool";
import DMS from "./Transport/Dms/DMS";

// CreateElements functional component for layout
const CreateElements = ({ Comp, title }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 800);
    if (window.innerWidth >= 800) {
      setIsDrawerOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

 
 
return (
  <div style={{ display: "flex", width: "100%", height: "100vh", overflow: "hidden" }}>
    {/* Sidebar with Menu Icon */}
    <div 
      style={{ 
        width: isMobile ? (isDrawerOpen ? "60%" : "3.5rem") : "17%", 
        background: "#fff", 
        height: "100%", 
        transition: 'width 0.3s', 
        display: "flex", 
        flexDirection: "column",
        justifyContent: "space-between", 
        position: 'relative'
      }}
    >
      {/* Menu Icon Button */}
      {isMobile && !isDrawerOpen && ( 
        <button 
          onClick={() => setIsDrawerOpen(!isDrawerOpen)} 
          style={{ 
            margin: '10px', 
            background: 'transparent', 
            border: 'none', 
            cursor: 'pointer', 
            position: 'absolute', 
            top: '1.5rem', 
          
          }}
        >
          <FaBars size={24} /> 
        </button>
      )}
  
      {/* Sidebar Menu */}
      {(isDrawerOpen || !isMobile) && (
        <div style={{ width: "100%", height: "100%", overflowY: "hidden" }}>
          <Menu />
        </div>
      )}

      {/* Close Arrow at the Bottom */}
      {isDrawerOpen && isMobile && (
        <button 
          onClick={() => setIsDrawerOpen(false)} 
          style={{ 
            background: 'transparent', 
            border: 'none', 
            cursor: 'pointer', 
            padding: '1rem', 
            alignSelf: 'center', 
            marginBottom: '10px' 
          }}
        >
          <FaArrowLeft size={24} /> 
        </button>
      )}
    </div>
  
    {/* Main Content Area */}
    <div 
      style={{ 
        flex: isMobile ? (isDrawerOpen ? "40%" : "calc(100% - 3.5rem)") : "83%", 
        background: '#FAFAFA', 
        height: "100%", 
        display: "flex", 
        flexDirection: "column", 
        transition: 'flex 0.3s' 
      }}
    >
       <AdminBar 
        title={title} 
        style={{ flex: "0 0 auto", marginLeft: "2rem" }} 
      />
      <div style={{ flex: "1 1 auto", overflowY: "auto" }}>
        {Comp}
      </div>
    </div>
  </div>
);
  };
  
// SchoolElements component
const SchoolElements = ({ children }) => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div className="w-full h-[8%]">
        <Header />
      </div>
      <div className="flex w-full h-[92%]">
        <div className="w-1/6 h-full">
          <Sidebar />
        </div>
        <div className="w-5/6 p-4">
          {children}
        </div>
      </div>
    </div>
  );
};

// Define routes
const routes = createBrowserRouter([
  {
    path: "/",
    element: <CreateElements Comp={<DefaultDashboard />} title="Dashboard" />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/logout",
    element: <Logout />
  },
  {
    path: "/dashboard",
    element: <CreateElements Comp={<DefaultDashboard />} title="Dashboard" />,
  },
  {
    path: "/behaviour/:class?",
    element: <CreateElements Comp={<BehaviourDashboard />} title="Behaviour Dashboard" />,
  },
  {
    path: "/students/overview",
    element: <CreateElements Comp={<Overview />} title="Student Information System" />,
  },
  {
    path: "/students/profile/:id",
    element: <CreateElements Comp={<StudentProfile />} title="Student Information System" />,
  },
  {
    path: "/students/admission",
    element: <CreateElements Comp={<Admission />} title="Student Information System" />,
  },
  {
    path: "/students/edit/:id",
    element: <CreateElements Comp={<Admission />} title="Student Information System" />,
  },
  {
    path: "/students/Attendance",
    element: <CreateElements Comp={<Attendance />} title="Students" />,
  },
  {
    path: "/teachers/teacheradmission",
    element: <CreateElements Comp={<TeachersAdmission />} title="Teachers Information System" />, 
  },
  {
    path: "/error",
    element: <CreateElements Comp={<ErrorPage />} />,
  },
  {
    path: '/add-school',
    element: <SchoolElements><AddSchool /></SchoolElements>,
  },
  {
    path: '/add-user/:id',
    element: <SchoolElements><AddUser /></SchoolElements>,
  },
  {
    path: '/school',
    element: <SchoolElements><SchoolDashboard /></SchoolElements>,
  },
  {
    path: '/schooldetails/:id',
    element: <SchoolElements><UserDetail /></SchoolElements>,
  },
  {
    path: '/edit-school/:id',
    element: <SchoolElements><EditSchool /></SchoolElements>,
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
  {
    path: '/teachers/overview',
    element: <CreateElements Comp={<TeacherOverview />} title="Teachers Information System" />,
  },
  {
    path: "/student-behaviour",
    element: <CreateElements Comp={<DefaultTable />} title="Student Behaviours" />,
  },
  {
    path: "/transport/dms",
    element: <CreateElements Comp={<DMS />} title="Transport " />,
  }
]);

// App component
function App() {
  return (
    <div style={{ display: "flex" }}>
      <RouterProvider router={routes} />
    </div>
  );
}

export default App;
