
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { apiBaseUrl } from '../../APIs/endpoints';

const AddUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    userName: '',
    userMobile: '',
    userEmail: '',
    password: '',
    confirmPassword: '',
  });
  var schoolId = JSON.parse(localStorage.getItem('school_info'));
      if( schoolId == null){
        schoolId=id
      }else{
        schoolId=schoolId.id
      }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

   const handleSubmitClick = () => {
    if (formData.password !== formData.confirmPassword) {
      alert('Passwords do not match');
      return;
    }

    const userDetails = {
    
      user: {
        first_name: formData.userName,
        email: formData.userEmail,
        password: formData.password,
      },
      mobile: formData.userMobile,
      school: schoolId,
      profile_type: 'admin',
    };

    axios.post(apiBaseUrl + '/api/profiles/', userDetails)
      .then((response) => {
        console.log(response);
        localStorage.removeItem('school_info');
        navigate('/school');
      })
      .catch((error) => {
        console.error('Error adding user:', error);
      });
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="mt-4" style={{ maxWidth: '70%', marginLeft: '5%' }}>
      <h5 className="text-[#303972] text-2xl font-bold font-poppins leading-9 text-left">
        User Registration
      </h5>
      <div className="pt-5 grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="w-full md:w-3/4">
          <label htmlFor="formUserName" className="text-[#303972] text-lg font-semibold font-poppins leading-5">
            Principal/Director Name
          </label>
          <input
            type="text"
            id="formUserName"
            name="userName"
            placeholder="Enter User Name"
            className="w-full h-12 border border-gray-300 rounded-lg px-3"
            value={formData.userName}
            onChange={handleInputChange}
          />
        </div>
        <div className="w-full md:w-3/4">
          <label htmlFor="formUserMobile" className="text-[#303972] text-lg font-semibold font-poppins leading-5">
            Mobile*
          </label>
          <input
            type="text"
            id="formUserMobile"
            name="userMobile"
            placeholder="Enter Mobile Number"
            className="w-full h-12 border border-gray-300 rounded-lg px-3"
            value={formData.userMobile}
            onChange={handleInputChange}
          />
        </div>
        <div className="w-full md:w-3/4">
          <label htmlFor="formUserEmail" className="text-[#303972] text-lg font-semibold font-poppins leading-5">
            Email*
          </label>
          <input
            type="email"
            id="formUserEmail"
            name="userEmail"
            placeholder="Enter Email"
            className="w-full h-12 border border-gray-300 rounded-lg px-3"
            value={formData.userEmail}
            onChange={handleInputChange}
          />
        </div>
        <div className="w-full md:w-3/4">
          <label htmlFor="formPassword" className="text-[#303972] text-lg font-semibold font-poppins leading-5">
            Password*
          </label>
          <div className="relative">
            <input
              type={showPassword ? 'text' : 'password'}
              id="formPassword"
              name="password"
              placeholder="Enter Password"
              className="w-full h-12 border border-gray-300 rounded-lg px-3"
              value={formData.password}
              onChange={handleInputChange}
            />
            <span
              className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
              onClick={toggleShowPassword}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
        </div>
        <div className="w-full md:w-3/4">
          <label htmlFor="formConfirmPassword" className="text-[#303972] text-lg font-semibold font-poppins leading-5">
            Confirm Password*
          </label>
          <div className="relative">
            <input
              type={showPassword ? 'text' : 'password'}
              id="formConfirmPassword"
              name="confirmPassword"
              placeholder="Enter Password"
              className="w-full h-12 border border-gray-300 rounded-lg px-3"
              value={formData.confirmPassword}
              onChange={handleInputChange}
            />
            <span
              className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
              onClick={toggleShowPassword}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
        </div>
      </div>
      <div className="mt-5" style={{ position: 'fixed', bottom: '30%', right: '10%', left: '0', width: '100%', justifyContent: 'flex-end', paddingRight: '28%' }}>
        <div className='text-end'>
          <button
            className="mr-2 text-orange-500 border border-orange-500 rounded shadow-none w-40 h-14 text-lg font-medium bg-transparent"
            onClick={() => navigate('/add-school')}
            style={{ borderRadius: '6px' }}
          >
            Back
          </button>
          <button
            className="text-white bg-[#EF805D] w-40 h-14 text-lg font-medium"
            onClick={handleSubmitClick}
            style={{ borderRadius: '6px' }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
