import React, { useState } from "react";
import './filter.scss';
import { CiFilter } from "react-icons/ci";
// import { FaEllipsisV, FaGripLines } from "react-icons/fa";
import { Select, Option } from "@material-tailwind/react";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline";
import { GoPlus } from "react-icons/go";
import { useNavigate } from "react-router-dom";
 
function Filter(props) {
    const [searchBy, setSearchBy] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedClass, setSelectedClass] = useState(null);
    const [selectedSection, setSelectedSection] = useState(null);

    const navigate = useNavigate();

    const onFilterClick = () => {
        props.getFilterInputs({
            searchBy: searchBy,
            year: selectedYear,
            class: selectedClass,
            section: selectedSection
        });
    }
    const handleAddTeacher = () => {
        navigate("/teachers/teacheradmission")
    }
    return (
        // <div className="filter-container">
        //     <div className="searchField">
        //         <input
        //             type="search"
        //             placeholder="Search By  Id/Name"
        //             className="search"
        //             onBlur={e => setSearchBy(e.target.value)} />
        //     </div>

        //     <div className="dropdowns-section">
        //         <div className="drop">
        //             <Select
        //                 label="Select Class"
        //                 value={selectedClass}
        //                 onChange={val => setSelectedClass(val)}
        //                 className="select-dropdowns">
        //                 <Option value="class1">Class 1</Option>
        //                 <Option value="class2">Class 2</Option>
        //                 <Option value="class3">Class 3</Option>
        //             </Select>
        //         </div>
        //         <div className="drop">
        //             <Select
        //                 label="Select Section"
        //                 value={selectedSection}
        //                 onChange={val => setSelectedSection(val)}
        //                 className="select-dropdowns">
        //                 <Option value="A">A</Option>
        //                 <Option value="B">B</Option>
        //                 <Option value="C">C</Option>
        //             </Select>
        //         </div>
        //         <div className="filter-icon" onClick={handleAddTeacher}>
        //             {/* <FaEllipsisV /> */}
        //             <GoPlus height={18} width={18} />
        //         </div>
        //         <div className="filter-icon" onClick={onFilterClick} >
        //             <CiFilter />
        //         </div>
        //         <div className="filter-icon">
        //             {/* <FaGripLines /> */}
        //             <AdjustmentsHorizontalIcon height={18} width={18} />
        //         </div>
        //     </div>

        // </div>
        <div className="filter-container" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <button
                className="add-teacher-button"
                onClick={handleAddTeacher}
                style={{
                    padding: '10px 20px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    backgroundColor: '#EF805D',
                    color: 'white',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    marginRight: '20px'  // Added more margin to the right
                }}
            >
                <GoPlus />
                Add Teacher
            </button>
        </div>
    )
}

export default Filter;