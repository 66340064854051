import React from 'react';

function DriverCard({ driver }) {
  return (
    <div className="relative mt-16">
      <div className="relative">
        <img
          src={driver.image}
          alt={driver.name}
          className="w-24 h-24 rounded-full absolute -top-12 left-4 border-4 border-white z-20"
        />
        <div className="absolute -top-9 left-24 z-10">
          <div className="bg-[#A67FFF80] p-1 px-6 transform skew-x-[20deg] rounded-lg shadow-md">
            <h2 className="text-xl font-semibold transform skew-x-[-20deg]">{driver.name}</h2>
          </div>
        </div>
      </div>
      <div className="bg-[#F5F1FF] rounded-lg p-4 pt-16 flex">
        <div className="space-y-2 text-center mt-2 mb-4 flex-grow">
          <p><strong>Employee ID:</strong> {driver.employeeId}</p>
          <p><strong>Assigned Bus No:</strong> {driver.assignedBusNo}</p>
          <p><strong>Route:</strong> {driver.route}</p>
          <p><strong>License No:</strong> {driver.licenseNo}</p>
          <p><strong>DL Expiry Date:</strong> {driver.dlExpiryDate}</p>
        </div>
        <div className="flex flex-col items-center justify-center ml-4">
          <div className="bg-white shadow-md rounded-lg p-2 text-center mb-20">
            <div className="text-sm font-semibold">4.3 <span className="text-blue-500">★</span></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DriverCard;