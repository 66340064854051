import React, { useEffect, useMemo, useState } from "react";
import './stuList.scss';
import { Avatar, Card, Menu, MenuHandler, MenuList, MenuItem, Button } from "@material-tailwind/react";
import { FaEllipsisV } from "react-icons/fa";
import { IoCallOutline } from "react-icons/io5";
import { TfiEmail } from "react-icons/tfi";
import { Link, useNavigate } from "react-router-dom";
import Pagination from '../../../commonComp/Pagination';
import { apiBaseUrl , endpoints } from "../../../APIs/endpoints";
import axios from "axios"; 

const StuList = ({studentsList}) => {
    const tableHead = ["Name", "ID", "Date of Joining", "Parent Name", "City", "Contact", "Class", "Action"];
    const tableRows = useMemo(() => studentsList || [], [studentsList]);
    const [listData, setListData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        setListData(tableRows.slice(0, 10));
    }, [tableRows]);

    const getRecords = (data, start, end) => {
        setListData(data.slice(start, end));
    };

    const handleEdit = async (student) => {
        try {
            const response = await axios.get(`${apiBaseUrl}${endpoints.STUDENT_DETAILS}${student.id}/`, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('access_token')}`
                }
            });
            const studentDetails = response.data;
            
            localStorage.setItem('stud_info', JSON.stringify(studentDetails));
            localStorage.setItem('parent_info', JSON.stringify(studentDetails.parents[0] || {}));
            localStorage.setItem('academic_Details', JSON.stringify(studentDetails.academics[0] || {}));
            localStorage.setItem('documents', JSON.stringify(studentDetails.documents[0] || {}));

            navigate(`/students/edit/${student.id}`);
        } catch (error) {
            console.error("Error fetching student details:", error);
            navigate(`/students/overview`);
        }
    };

    return (
        <Card className="m-4 overflow-hidden">
            <table className="table-container">
                <thead>
                    <tr>
                        {
                            tableHead.map((head, i) => (
                                <th
                                    key={head + i}
                                    className="border-b text-left border-blue-gray-100 p-4"
                                >
                                    {head}
                                </th>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        listData.map((data, i) => {
                            const isLast = i === listData.length - 1;
                            const classes = isLast ? "p-4 text-left" : "p-4 border-b border-blue-gray-50 text-left";
                            return (
                                <tr key={data.name}>
                                    <td className={classes}>
                                        <Avatar src={`${apiBaseUrl}${data.student_photo}`} alt="avatar" size="sm" />
                                        <Link to={`/students/profile/${data.id}`} className="w-full"><span className="ml-3" style={{fontWeight:'bold'}}>{data.name}</span></Link>
                                    </td>
                                    <td className={classes}>{data.id}</td>
                                    <td className={classes}>{data.joining_date}</td>
                                    <td className={classes}>{data.parent_name}</td>
                                    <td className={classes}>{data.city}</td>
                                    <td className={classes}>
                                        <div className="flex justify-items-center text-[#EF805D]  gap-3" >
                                            <div className="text-xl p-2 bg-[#fff1f2] border rounded-full cursor-pointer"><IoCallOutline title={data.mobile_no} /></div>
                                            <div className="text-lg p-2 bg-[#fff1f2] border rounded-full cursor-pointer">
                                                <a href={`mailto:${data.email}`} title={data.email}><TfiEmail /></a>
                                            </div>
                                        </div>
                                    </td>
                                    <td className={classes}>{data.class}</td>
                                    <td className={classes}>
                                        <Menu>
                                            <MenuHandler>
                                                <Button className="p-2" variant="text"><FaEllipsisV size="0.8em" /></Button>
                                            </MenuHandler>
                                            <MenuList className="min-w-[80px] max-w-[100px] bg-white">
                                                <MenuItem onClick={() => handleEdit(data)} className="text-black text-lg">Edit</MenuItem>
                                                {/* Add more menu items as needed */}
                                            </MenuList>
                                        </Menu>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            <Pagination data={tableRows} getRecords={getRecords} itemsPerPage='10'/>
        </Card>
    )
}
export default StuList;