import React, { useState } from 'react';
import DriverCard from './DriverCard';
import { FiPlusCircle } from 'react-icons/fi';

const dummyDrivers = [
  {
    id: 1,
    name: "John Doe",
    image: "http://localhost:3000/images/boy_image.png",
    employeeId: "EMP001",
    assignedBusNo: "BUS001",
    route: "Route A",
    licenseNo: "DL12345678",
    dlExpiryDate: "2024-12-31"
  },
  {
    id: 2,
    name: "Jane Smith",
    image: "http://localhost:3000/images/Student.png",
    employeeId: "EMP002",
    assignedBusNo: "BUS002",
    route: "Route B",
    licenseNo: "DL87654321",
    dlExpiryDate: "2025-06-30"
  },  
  {
    id: 3,
    name: "John Doe",
    image: "http://localhost:3000/images/Student.png",
    employeeId: "EMP001",
    assignedBusNo: "BUS001",
    route: "Route A",
    licenseNo: "DL12345678",
    dlExpiryDate: "2024-12-31"
  },
  {
    id: 4,
    name: "John Doe",
    image: "http://localhost:3000/images/Student.png",
    employeeId: "EMP001",
    assignedBusNo: "BUS001",
    route: "Route A",
    licenseNo: "DL12345678",
    dlExpiryDate: "2024-12-31"
  },
  {
    id: 5,
    name: "John Doe",
    image: "http://localhost:3000/images/Student.png",
    employeeId: "EMP001",
    assignedBusNo: "BUS001",
    route: "Route A",
    licenseNo: "DL12345678",
    dlExpiryDate: "2024-12-31"
  },
  {
    id: 6,
    name: "John Doe",
    image: "http://localhost:3000/images/Student.png",
    employeeId: "EMP001",
    assignedBusNo: "BUS001",
    route: "Route A",
    licenseNo: "DL12345678",
    dlExpiryDate: "2024-12-31"
  },
  {
    id: 7,
    name: "John Doe",
    image: "http://localhost:3000/images/Student.png",
    employeeId: "EMP001",
    assignedBusNo: "BUS001",
    route: "Route A",
    licenseNo: "DL12345678",
    dlExpiryDate: "2024-12-31"
  },
  {
    id: 8,
    name: "John Doe",
    image: "http://localhost:3000/images/Student.png",
    employeeId: "EMP001",
    assignedBusNo: "BUS001",
    route: "Route A",
    licenseNo: "DL12345678",
    dlExpiryDate: "2024-12-31"
  },
  {
    id: 9,
    name: "John Doe",
    image: "http://localhost:3000/images/Student.png",
    employeeId: "EMP001",
    assignedBusNo: "BUS001",
    route: "Route A",
    licenseNo: "DL12345678",
    dlExpiryDate: "2024-12-31"
  },
  {
    id: 10,
    name: "John Doe",
    image: "http://localhost:3000/images/Student.png",
    employeeId: "EMP001",
    assignedBusNo: "BUS001",
    route: "Route A",
    licenseNo: "DL12345678",
    dlExpiryDate: "2024-12-31"
  },

  // Add more dummy data as needed
];

function DMS() {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredDrivers = dummyDrivers.filter(driver =>
    driver.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="mb-4">
      <div className="bg-[#7239F5] text-white w-full py-4 px-6">
        <h1 className="text-2xl font-bold">DMS</h1>
      </div>
      <div className="flex justify-between mb-6 mt-6 mx-10">
        <div className="searchField">
            <input
                type="search"
                placeholder="Search By Name"
                className="search border border-gray-300 rounded-full py-2 px-3 shadow-sm w-64 text-sm text-center placeholder:text-center placeholder:text-sm"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
        </div>
        <button className="flex items-center bg-[#7239F5] text-white px-4 py-2 rounded hover:bg-[#5F2DD3] transition-colors duration-300">
          <FiPlusCircle className="mr-2" />
          <span>Add Driver</span>
        </button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mx-10">
        {filteredDrivers.map(driver => (
          <DriverCard key={driver.id} driver={driver} />
        ))}
      </div>
    </div>
  );
}

export default DMS;