import {useEffect, useState} from "react"
import axios from "axios";
import { apiBaseUrl } from "../APIs/endpoints";

const Logout = () => {
    useEffect(() => {
       (async () => {
         try {
           const {data} = await  
                 axios.post(apiBaseUrl + '/api/logout',{
                 refresh_token:localStorage.getItem('refresh_token')
                 } ,{headers: {'Content-Type': 'application/json', "Authorization": `Bearer ${localStorage.getItem('access_token')}`}},  
                 {withCredentials: true});
               } catch (e) {
                console.log('logout not working', e)
              }
           localStorage.clear();
           axios.defaults.headers.common['Authorization'] = null;
           window.location.href = '/login'
         })();
    }, []);
    return (
       <div></div>
     )
}

export default Logout