import React, { useState , useEffect} from 'react';
import './form.scss';
import axios from 'axios';
import { apiBaseUrl as baseEndpoint } from '../../../APIs/endpoints';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate , useLocation} from 'react-router-dom';



const StudentForm = (props) => {

    function submitStudentInfo(student_id, status) {
        props.getParentForm(true, student_id, status)
    }

    var default_data = {
        first_name: '',
        middle_name: '',
        last_name: '',
        gender: '',
        aadhar_no: '',
        date_of_birth: '',
        student_photo: null,
        hostel_requred: false,
        travel_requred: false,
        blood_group: '',
        languages_known: '',
        address: '',
        id: null
    };

    const [formData, setFormData] = useState(!localStorage.getItem("stud_info") ? default_data : JSON.parse(localStorage.getItem("stud_info")));
    const [errors, setErrors] = useState({});
    const [photoPreview, setPhotoPreview] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
    const token = localStorage.getItem('access_token'); 

    if (!token) { 
        navigate('/login'); 
    }

    const editPath = '/students/edit';
    const admissionPath = '/students/admission';

    // Clear localStorage when navigating away from the edit or admission pages
    if (!location.pathname.startsWith(editPath) && !location.pathname.startsWith(admissionPath)) {
        localStorage.removeItem('academic_Details');
        localStorage.removeItem('stud_info');
        localStorage.removeItem('parent_info');
        localStorage.removeItem('documents');
    }
}, [navigate, location.pathname]);
    
    
    const handleTravel = (event) => {
        setFormData({ ...formData, ['travel_requred']: event.target.checked })
    };
    const handleHostel = (event) => {
        setFormData({ ...formData, ['hostel_requred']: event.target.checked })
    };

    const handleUploadPhoto = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFormData({ ...formData, student_photo: file });
            setPhotoPreview(URL.createObjectURL(file));
        }
    };

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value })
    }

    const updateBloodGroup = (event) => {
        setFormData({ ...formData, blood_group: event.target.value });
    };

    const updateGender = (event) => {
        setFormData({ ...formData, gender: event.target.value });
    };
    const validateForm = () => {
        let valid = true;
        const newErrors = {};
    
        const firstName = String(formData.first_name).trim();
        const lastName = String(formData.last_name).trim();
        const aadharNo = String(formData.aadhar_no).trim();
        const bloodGroup = String(formData.blood_group).trim();
        const gender = String(formData.gender).trim();
        const dateOfBirth = String(formData.date_of_birth).trim();
        const student_photo = String(formData.student_photo).trim();
        const address = String(formData.address).trim();
    
        if (!firstName) {
            newErrors.first_name = "First Name is required";
            valid = false;
        }
        if (!lastName) {
            newErrors.last_name = "Last Name is required";
            valid = false;
        }
        newErrors.aadhar_no = [];
        if (!aadharNo) {
            newErrors.aadhar_no.push("Aadhar Number is required");
            valid = false;
        } else if (!/^\d{12}$/.test(aadharNo)) {
            newErrors.aadhar_no.push("Aadhar Number must be exactly 12 digits");
            valid = false;
        }
        if (newErrors.aadhar_no.length > 0) {
            newErrors.aadhar_no = newErrors.aadhar_no.join(", ");
        }

        if (!student_photo) {
            newErrors.student_photo = "Student photo is required";
            valid = false;
        }
        if (!bloodGroup) {
            newErrors.blood_group = "Blood Group is required";
            valid = false;
        }
        if (!gender) {
            newErrors.gender = "Gender is required";
            valid = false;
        }
        if (!dateOfBirth) {
            newErrors.date_of_birth = "Date of Birth is required";
            valid = false;
        }
        if (!address) {
            newErrors.address = "Address is required";
            valid = false;
        }
    
        setErrors(newErrors);
        return valid;
    };

    function handleSubmit(event) {
        event.preventDefault();
    
        if (!validateForm()) {
            return;
        }
    
        const endpoint = localStorage.getItem('stud_info') 
            ? `${baseEndpoint}/api/students/${formData.id}/` 
            : `${baseEndpoint}/api/students/`;
    
        const method = localStorage.getItem('stud_info') ? 'PUT' : 'POST';
    
        axios({
            method,
            url: endpoint,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
                "Authorization": `Bearer ${localStorage.getItem('access_token')}`
            }
        })
        .then(response => {
            const studentDetails = response.data;
            localStorage.setItem('stud_info', JSON.stringify(studentDetails));
            toast.success("Student information submitted successfully!");
            setTimeout(() => {
                props.getParentForm(true);
            }, 1000);
        })
        .catch(err => {
            const errorMessage = err.response && err.response.data 
                ? `Error: ${err.response.status} - ${err.response.statusText}\n${JSON.stringify(err.response.data, null, 2)}`
                : "An unexpected error occurred. Please try again later.";
            toast.error(errorMessage);
        });
    }
    
    const [student_data, setstudent_data] = useState([]);
    
    const handleBack = () => {
        localStorage.removeItem('stud_info');
        localStorage.removeItem('parent_info');
        localStorage.removeItem('academic_Details');
        localStorage.removeItem('documents');
        
        navigate(-1);
    };
    const saveAndExit = () => {
        if (validateForm()) {
            const endpoint = localStorage.getItem('stud_info') 
                ? `${baseEndpoint}/api/students/${formData.id}/` 
                : `${baseEndpoint}/api/students/`;
            const method = localStorage.getItem('stud_info') ? 'PUT' : 'POST';
            
            axios({
                method,
                url: endpoint,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": `Bearer ${localStorage.getItem('access_token')}`
                }
            })
            .then(response => {
                const studentDetails = response.data;
                localStorage.setItem('stud_info', JSON.stringify(studentDetails));
                toast.success("Student information saved successfully!");
                console.log(studentDetails);
                setTimeout(() => {
                    localStorage.removeItem('stud_info');
                    localStorage.removeItem('parent_info');
                    localStorage.removeItem('academic_Details');
                    localStorage.removeItem('documents');
                    navigate(-1);
                }, 1000);
            })
            .catch(err => {
                const errorMessage = err.response && err.response.data 
                    ? `Error: ${err.response.status} - ${err.response.statusText}\n${JSON.stringify(err.response.data, null, 2)}`
                    : "An unexpected error occurred. Please try again later.";
                toast.error(errorMessage);
            });
        }
    }

    return (
        <div className="w-full flex items-center justify-center" >
            <form onSubmit={handleSubmit} encType="multipart/form-data" method="post" className="w-full max-w-3xl">
                <div className="mb-5 mt-5 flex items-center justify-between">
                    <h3 className="font-poppins text-2xl font-bold leading-9 text-left text-[#303972]">
                        {localStorage.getItem('stud_info') ? 'Edit Student Details' : 'Student Details'}
                    </h3>
                </div>
                <div className="mb-5 flex justify-between">
                    <div className="pr-5 w-1/2">
                        <div className="mb-4">
                            <label className="font-poppins text-lg font-semibold  text-[#303972]" htmlFor='first_name'>First Name *</label>
                            <input
                                type="text"
                                placeholder="Enter text here"
                                onChange={handleChange}
                                name='first_name'
                                value={formData.first_name}
                                className="w-full h-12 border border-gray-300 rounded px-2"
                            />
                            {errors.first_name && <p className="text-red-500 text-sm">{errors.first_name}</p>}
                        </div>
                        <div className="mb-4">
                            <label className="font-poppins text-lg font-semibold  text-[#303972]" htmlFor="last_name">Last Name *</label>
                            <input
                                type="text"
                                placeholder="Enter text here"
                                onChange={handleChange}
                                value={formData.last_name}
                                name='last_name'
                                className="w-full h-12 border border-gray-300 rounded px-2"
                            />
                            {errors.last_name && <p className="text-red-500 text-sm">{errors.last_name}</p>}
                        </div>
                        <div className="mb-4">
                            <label className="font-poppins text-lg font-semibold  text-[#303972]" htmlFor="aadhar_no">Aadhar Number *</label>
                            <input
                                type="number"
                                placeholder="Enter text here"
                                onChange={handleChange}
                                value={formData.aadhar_no}
                                name='aadhar_no'
                                className="w-full h-12 border border-gray-300 rounded px-2"
                                minlength="12" maxlength="12" 
                            />
                             {errors.aadhar_no && <p className="text-red-500 text-sm">{errors.aadhar_no}</p>}
                        </div>
                        <div className="mb-4">
                            <div className="flex flex-col mt-1 mb-4">
                                <label
                                    className="font-poppins text-lg font-semibold text-[#303972]"
                                    htmlFor="student_photo"
                                >
                                    Upload Photo *
                                </label>
                                <input
                                    id="student_photo"
                                    // value={formData.student_photo}
                                    type="file"
                                    name="student_photo"
                                    onChange={handleUploadPhoto}
                                    className="hidden" 
                                />
                                <div className="flex items-center">
                                    <button
                                        type="button"
                                        onClick={() => document.getElementById('student_photo').click()}
                                        className={`w-1/2 border border-gray-300 rounded px-4 py-2 text-black focus:outline-none focus:ring-2 focus:ring-opacity-50 ${
                                            formData.student_photo
                                                ? 'bg-[#8D8C8C] hover:bg-[#6B6B6B] focus:ring-[#8D8C8C]'
                                                : 'bg-[#D9D9D9] hover:bg-[#BFBFBF] focus:ring-[#D9D9D9]'
                                        }`}
                                    >
                                        {formData.student_photo ? 'Change Photo' : 'Upload Photo'}
                                    </button> 
                                    {photoPreview && (
                                        <img
                                            src={photoPreview}
                                            alt="Photo Preview"
                                            className="ml-4 w-10 h-10 object-cover rounded"
                                        />
                                    )}
                                </div>
                                {errors.student_photo && (
                                    <p className="text-red-500 text-sm">{errors.student_photo}</p>
                                )}
                            </div>
                        </div>
                        <div className="mt-4 mb-4">
                            <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor="blood_group">Blood Group *</label>
                            <select
                                id="blood_group"
                                value={formData.blood_group}
                                onChange={updateBloodGroup}
                                className="w-full h-12 bg-white text-gray-700 border border-gray-300 rounded-lg  px-2"
                            >
                                <option value="">Select</option>
                                <option value="A+">A+</option>
                                <option value="O+">O+</option>
                                <option value="AB+">AB+</option>
                                <option value="B+">B+</option>
                                <option value="A-">A-</option>
                                <option value="B-">B-</option>
                                <option value="AB-">AB-</option>
                                <option value="O-">O-</option>
                            </select>
                            {errors.blood_group && <p className="text-red-500 text-sm">{errors.blood_group}</p>}
                        </div>
                        <div className="mb-4">
                            <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor="address">Address *</label>
                            <textarea
                                id="address"
                                name="address"
                                placeholder="Enter address here"
                                value={formData.address}
                                onChange={handleChange}
                                className="w-full h-24 border border-gray-300 rounded px-2 py-1 resize-none"
                            ></textarea>
                            {errors.address && <p className="text-red-500 text-sm">{errors.address}</p>}
                        </div>
                    </div>
                    <div className="pl-5 w-1/2">
                        <div className="mb-4">
                            <label className="font-poppins text-lg font-semibold  text-[#303972]" htmlFor='middle_name'>Middle Name</label>
                            <input
                                type="text"
                                placeholder="Enter text here"
                                onChange={handleChange}
                                value={formData.middle_name}
                                name='middle_name'
                                className="w-full h-12 border border-gray-300 rounded px-2"
                            />
                        </div>
                         <div className="mb-4">
                                <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor="gender">
                                    Gender *
                                </label>
                                <select
                                    variant="outlined"
                                    value={formData.gender}
                                    onChange={updateGender}
                                    className="w-full h-12 bg-white text-gray-700 border border-gray-300 rounded-lg px-2"
                                    >
                                    <option value="">Select</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </select>
                                {errors.gender && <p className="text-red-500 text-sm">{errors.gender}</p>}
                            </div>
                        <div className="mb-4">
                            <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor='date_of_birth'>Date of Birth *</label>
                            <input
                                type="date"
                                onChange={handleChange}
                                value={formData.date_of_birth}
                                name='date_of_birth'
                                className="w-full h-12 border border-gray-300 rounded px-2"
                            />
                            {errors.date_of_birth && <p className="text-red-500 text-sm">{errors.date_of_birth}</p>}
                        </div>
                        <div className="mb-3 flex justify-between">
                            <div className="flex flex-col mt-1 mb-3">
                                <label className="font-poppins text-lg font-semibold text-[#303972]">Hostel *</label>
                                <div className="flex items-center space-x-2">
                                <input
                                    type="checkbox"
                                    name="hostel_requred"
                                    checked={formData.hostel_requred}
                                    onChange={handleHostel}
                                    className="relative inline-block h-7 w-[70%] rounded-full bg-gray-200 cursor-pointer appearance-none checked:bg-[#EF805D]"
                                />
                                </div>
                            </div>
                            <div className="flex flex-col mt-1 mb-3">
                                <label className="font-poppins text-lg font-semibold text-[#303972]">School Bus *</label>
                                <div className="flex items-center space-x-2">
                                <input
                                    type="checkbox"
                                    name="travel_requred"
                                    checked={formData.travel_requred}
                                    onChange={handleTravel}
                                    className="relative inline-block h-7 w-[46%] rounded-full bg-gray-200 cursor-pointer appearance-none checked:bg-[#EF805D]"
                                />
                                </div>
                            </div>
                            </div>


                        <div className=" mb-4">
                            <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor='languages_known'>Languages Known </label>
                            <input
                                type="text"
                                placeholder="Enter text here"
                                onChange={handleChange}
                                value={formData.languages_known}
                                name='languages_known'
                                className="w-full h-12 border border-gray-300 rounded px-2"
                            />
                        </div>

                    </div>
                </div>
                <div className='flex justify-end'>
                    <div className="button-container">
                        {localStorage.getItem('stud_info') ? (
                            <>
                                <button type="button" onClick={handleBack} className="bg-gray-300 text-black px-4 py-2 rounded mr-2 button">Back</button>
                                <button type="submit"  className="bg-blue-600 text-white px-4 py-2 rounded button-active">Update</button>
                                <button type="button" onClick={saveAndExit} className="bg-blue-600 text-white px-4 py-2 rounded button-active">Save and Exit</button>

                            </>
                        ) : (
                            <>
                                <button type="button" onClick={handleBack} className="bg-gray-300 text-black px-4 py-2 rounded mr-2 button">Back</button>
                                <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded button-active">Next</button>
                            </>
                        )}

                    </div>
                </div>
                <div>{student_data.id}</div>
            </form>
                <div>
                {/* Other components */}
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />

            </div>
        </div>

    );
};

export default StudentForm;
