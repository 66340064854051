import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { FaCalendar, FaDownload, FaEye } from 'react-icons/fa';
import { BiSolidTrashAlt } from 'react-icons/bi';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { Card, Typography } from "@material-tailwind/react";
import { apiBaseUrl as baseEndpoint } from '../../../APIs/endpoints';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react";

const DocumentsDetails = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const TABLE_HEAD = ["File Name", "File Size", "Date", "Action"];

    const [documents, setDocuments] = useState([]);
    const [formData, setFormData] = useState({
        doc_name: '',
        doc_type: '',
        document: null,
        student: null,
    });

    useEffect(() => {
        const token = localStorage.getItem('access_token'); 

        if (!token) { 
            navigate('/login'); 
        }

        const editPath = '/students/edit';

        // Clear localStorage when navigating away from the edit page 
        if (!location.pathname.startsWith(editPath)) {
            // Uncomment if needed
            // localStorage.removeItem('academic_Details');
            // localStorage.removeItem('stud_info');
            // localStorage.removeItem('parent_info');
            // localStorage.removeItem('documents');
        }
    }, [navigate, location.pathname]);

    const [selectedDocumentName, setSelectedDocumentName] = useState("");
    const [open, setOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleOpenDialog = () => {
        setOpen(prevOpen => !prevOpen); 
    };

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            setSelectedFile(file);
            setSelectedDocumentName(file.name);

            // Update the formData state with the selected file and document name
            setFormData(prevFormData => ({
                ...prevFormData,
                document: file,
                doc_name: file.name,
            }));
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: ".doc,.docx,.pdf",
        noClick: true, 
    });

    const handleCancel = () => {
        setSelectedFile(null);
        setSelectedDocumentName('');
        setOpen(false);
    };

    const updatedoc_type = (val) => {
        setFormData({ ...formData, doc_type: val });
    };

    const backtoAcadamics = () => {
        props.getAcademicsForm(true);
    };

    const Nextbutton = () => {
        localStorage.removeItem('academic_Details');
        localStorage.removeItem('stud_info');
        localStorage.removeItem('parent_info');
        localStorage.removeItem('documents');
        navigate(`/students/overview`);
    };

    const handleDelete = (index) => {
        const updatedDocuments = documents.filter((_, i) => i !== index);
        setDocuments(updatedDocuments);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const student = JSON.parse(localStorage.getItem("stud_info"));
        if (student) {
            // Check if document name and document file are present
            if (!formData.doc_name || !formData.document) {
                toast.error("Please provide both the document name and file.");
                return;
            }

            const data = new FormData();
            data.append('document', formData.document);
            data.append('doc_name', formData.doc_name);
            data.append('doc_type', formData.doc_type);
            data.append('student', student.id);

            // Log FormData key-value pairs
            for (let pair of data.entries()) {
                console.log(`${pair[0]}: ${pair[1]}`);
            }

            axios.post(`${baseEndpoint}/api/students/${student.id}/documents/`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`, 
                },
            })
            .then((response) => {
                // Update state with new document details
                setDocuments([
                    ...documents,
                    {
                        name: formData.doc_name,
                        size: formData.document.size,
                        date: new Date().toLocaleDateString(),
                    },
                ]);
                // Clear the form and dialog
                setFormData({ doc_name: '', doc_type: '', document: null, student: null });
                setSelectedDocumentName("");
                setSelectedFile(null);
                setOpen(false);
                toast.success("Document uploaded successfully!");
            })
            .catch((error) => {
                console.error("Error response:", error.response); 
                const errorMessage = error.response
                    ? `Error: ${error.response.data || "Unknown error occurred"}`
                    : "Unknown error occurred";
                toast.error(errorMessage);
            });
        } else {
            toast.error("No student information found in local storage.");
        }
    };

    return (
        <div className="w-full flex items-center justify-center">
            <form encType="multipart/form-data" className="w-full max-w-3xl" onSubmit={handleSubmit}>
                <div className="mb-5 flex items-center justify-between">
                    <h3 className="text-2xl font-bold">
                        {formData.id ? 'Update Documents' : 'Documents'}
                    </h3>
                </div>
                <div className="mb-5 flex items-center justify-between">
                    <div>
                        <select 
                            name='doc_type' 
                            onChange={(e) => updatedoc_type(e.target.value)} 
                            className="w-full h-12 bg-white border border-gray-300 rounded px-2"
                            value={formData.doc_type}
                        >
                            <option value="">Select</option>
                            <option value="aadhaar">Aadhaar</option>
                            <option value="transfer-certificate">Transfer Certificate</option>
                            <option value="bonafide">Study/Bonafide Certificate</option>
                            <option value="cast">Caste</option>
                            <option value="report-card">Report Card</option>
                        </select>
                    </div>
                    <div className="flex items-center space-x-4">
                        {selectedDocumentName && (
                            <span className="text-gray-600">{selectedDocumentName}</span>
                        )}
                        <label
                            className="bg-blue-600 text-white px-4 py-2 rounded cursor-pointer flex items-center space-x-2"
                            style={{ backgroundColor: '#EF805D', color: 'white' }}
                            onClick={handleOpenDialog}
                        >
                            <FaDownload />
                            <span>Upload Document</span>
                        </label>
                        <Dialog
                            open={open}
                            handler={handleOpenDialog}
                            animate={{
                                mount: { scale: 1, y: 0 },
                                unmount: { scale: 0.9, y: -100 },
                            }}
                        >
                        <DialogHeader>Upload Document</DialogHeader>
                        <DialogBody className="py-10">
                            <div
                                {...getRootProps({ className: 'dropzone' })}
                                className="border-2 border-dashed border-gray-300 p-6 py-10 bg-[#F0F0F0] rounded-md cursor-pointer text-center flex flex-col justify-center items-center"
                                style={{ height: '50%' }}
                            >
                                <input {...getInputProps()} />
                                <p>Drag and drop files here</p>
                                <button
                                    type="button"
                                    className="mt-5 px-4 py-2 bg-white text-black rounded-md hover:bg-blue-600"
                                    onClick={() => document.querySelector('input[type="file"]').click()}
                                >
                                    Browse Files
                                </button>
                                {selectedFile && (
                                    <p className="mt-4 text-green-600">
                                        {selectedFile.name} selected
                                    </p>
                                )}
                            </div>
                        </DialogBody>
                        <DialogFooter className='my-10'>
                            <Button
                                variant="text"
                                className="bg-gray-300 text-black px-4 py-2 rounded cursor-pointer mr-4"
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="bg-blue-600 text-white px-4 py-2 rounded cursor-pointer"
                                type="button"
                                style={{ backgroundColor: '#EF805D', color: 'white' }}
                                onClick={handleSubmit}
                                disabled={!selectedFile}
                            >
                                <span>Upload</span>
                            </Button>

                        </DialogFooter>
                    </Dialog>
                    </div>
                </div>
                <div className="w-full">
                    <Card className="overflow-auto h-full w-full">
                        <table className="w-full min-w-max table-auto text-left">
                            <thead>
                                <tr>
                                    {TABLE_HEAD.map((head) => (
                                        <th
                                            key={head}
                                            className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                                        >
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="leading-none opacity-70 font-bold"
                                            >
                                                {head}
                                            </Typography>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {documents.map((document, index) => (
                                    <tr key={index} className="even:bg-blue-gray-50/50">
                                        <td className="p-4">{document.name}</td>
                                        <td className="p-4">{document.size}</td>
                                        <td className="p-4">
                                            <div className="flex items-center gap-2">
                                                <FaCalendar size={16} /> {document.date}
                                            </div>
                                        </td>
                                        <td className="p-4">
                                            <div className="flex items-center gap-4">
                                                <BiSolidTrashAlt
                                                    size={18}
                                                    color="red"
                                                    onClick={() => handleDelete(index)}
                                                    className="cursor-pointer"
                                                />
                                                <FaEye
                                                    size={18}
                                                    color="green"
                                                    className="cursor-pointer"
                                                    onClick={() => toast.info("View functionality not implemented yet.")}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Card>
                </div>
                <br />
                <div className="flex justify-end">
                    <button
                        className="bg-blue-600 text-white px-4 py-2 rounded cursor-pointer"
                        type="button"
                        style={{ backgroundColor: '#EF805D', color: 'white' }}
                        onClick={backtoAcadamics}
                    >
                        Back
                    </button>
                    <button
                        className="bg-blue-600 text-white px-4 py-2 rounded cursor-pointer"
                        type="button"
                        style={{ backgroundColor: '#EF805D', color: 'white' }}
                        onClick={Nextbutton}
                    >
                        Next
                    </button>
                </div>
            </form>
            <ToastContainer />
        </div>
    );
}

export default DocumentsDetails;
