import React, { useState, useEffect } from "react";
import { IoChevronBackOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { FiPhone } from "react-icons/fi";
import { IoCalendarClearOutline } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import OverviewSection from "./OverviewSection";
import DocumentsSection from "./DocumentsSection";
import AcademicSection from "./AcademicSection";
import axios from "axios";
import { apiBaseUrl, endpoints } from "../../../APIs/endpoints";
import { Spinner } from "@material-tailwind/react";

// Commented out demo JSON data
/*
const formData = {
  name: "John Doe",
  class: "X-B",
  phone: "1234567890",
  dob: "15/08/2005",
  location: "New York",
  studentId: "#987654",
  hostelId: "H-123",
  schoolBusNo: "42",
  board: "CBSE",
  gender: "Male",
  parentPhoneNo: "9876543210",
  classTeacher: "Ms. Smith",
  overview: {
    classParticipation: 78,
    attendance: 92,
    motivation: 65,
    actions: [
      {
        text: "Upload Bonafide Certificate",
        actionType: "urgent",
        uploadButton: true
      },
      {
        text: "Upload Caste Certificate",
        actionType: "urgent",
        uploadButton: true
      }
    ],
    performanceData: {
      '7days': [44, 55, 57, 56, 61, 58, 63],
      '30days': Array.from({length: 30}, 
      '12months': [65, 59, 80, 81, 56, 55, 40, 70, 75, 68, 72, 77]
    }
  },
  documents: [
    { name: "Aadhar Card", status: "added", date: "2023-05-15", path: "/documents/aadhar_card.pdf" },
    { name: "Transfer Certificate", status: "added", date: "2023-04-10", path: "/documents/transfer_certificate.pdf" },
    { name: "Bonafide Certificate", status: "missing", date: null, path: null },
    { name: "Caste Certificate", status: "missing", date: null, path: null },
    { name: "Report Card", status: "added", date: "2023-05-01", path: "/documents/report_card.pdf" },
  ],
  academic: {
    class: "VII",
    section: "C",
    attendance: 90,
    attentionSpan: 78,
    motivation: 65,
    subjects: [
      { name: "Mathematics", attendance: 95, attentionSpan: 85 },
      { name: "Science", attendance: 92, attentionSpan: 80 },
      { name: "English", attendance: 88, attentionSpan: 75 },
      { name: "History", attendance: 93, attentionSpan: 70 },
    ]
  }
};
*/

const StudentProfile = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [activeSection, setActiveSection] = useState("overview");
  const [studentData, setStudentData] = useState(null);
  const [loading, setLoading] = useState(true); // Added loading state

  useEffect(() => {
    const fetchStudentData = async () => {
      setLoading(true); // Set loading to true at the start of the fetch
      try {
        const response = await axios.get(`${apiBaseUrl}${endpoints.STUDENT_PROFILE}${id}/`, {
          headers: {
            "Authorization": `Bearer ${localStorage.getItem('access_token')}`
          }
        });
        setStudentData(response.data);
      } catch (error) {
        console.error("Error fetching student data:", error);
      } finally {
        setLoading(false); // Set loading to false after the fetch
      }
    };

    fetchStudentData();
  }, [id]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  // if (!studentData) {
  //   return <div>No data available for this student.</div>;
  // }

  return (
    <div className="w-full mx-auto pt-6 pr-6 pb-2 pl-6">
      <div className="w-full mt-4">
        <div className="flex flex-col lg:flex-row justify-between">
          <div className="lg:w-9/12 mr-5">
            <div className="mb-4 flex items-center justify-start">
              <div className="ml-5 w-2  mr-4 ">
                <IoChevronBackOutline className="mt-1 bg-black   text-white rounded-full cursor-pointer" onClick={handleBack} />
              </div>
              <h3 className="text-2xl font-bold">Student Profile</h3>
            </div>
            {loading ? (
                <div className="flex justify-center items-start h-screen">
                    <Spinner className="h-12 w-12" color="blue" />
                </div>
            ) : (
            <div className="flex flex-col md:flex-row justify-around mb-4">
              <button
                type="button"
                className={`border rounded-full w-full md:w-52 h-12 ${
                  activeSection === "overview"
                    ? "bg-[#FFF2EE] border-[#EF805D] text-[#EF805D]"
                    : "bg-gray-300"
                }`}
                onClick={() => handleSectionChange("overview")}
              >
                Overview
              </button>
              <button
                type="button"
                className={`border rounded-full w-full md:w-52 h-12 ${
                  activeSection === "documents"
                    ? "bg-[#FFF2EE] border-[#EF805D] text-[#EF805D]"
                    : "bg-gray-300"
                }`}
                onClick={() => handleSectionChange("documents")}
              >
                Documents
              </button>
              {/* <button
                type="button"
                className={`border rounded-full w-full md:w-52 h-12 ${
                  activeSection === "academic"
                    ? "bg-[#FFF2EE] border-[#EF805D] text-[#EF805D]"
                    : "bg-gray-300"
                }`}
                onClick={() => handleSectionChange("academic")}
              >
                Academic
              </button> */}
            </div>)}
            {studentData && activeSection === "overview" && <OverviewSection overviewData={studentData.overview} />}
            {studentData && activeSection === "documents" && <DocumentsSection documents={studentData.documents} studentId={id} />}
            {/* {studentData && activeSection === "academic" && <AcademicSection academicInfo={studentData.academic} />} */}
          </div>
          <div className="ml-5 flex bg-white items-center justify-center p-7 rounded-2xl w-full lg:w-4/12">
            <div className="flex flex-col justify-evenly h-full w-9/12">
              <div className="flex flex-col items-center pb-4">
                <div className="bg-[#FFF2EE] w-32 h-32 flex items-center justify-center rounded-full overflow-hidden">
                  <img src={studentData ? `${apiBaseUrl}${studentData.student_photo}` : ''} alt="Student" className="mb-2" />
                </div>
                <h2 className="text-xl font-bold">{studentData ? studentData.name : ''}</h2>
                <h5 className="text-md mb-4 text-sm">Class: {studentData ? studentData.class : ''}</h5>
              </div>
              
              <div
                className=" h-0.1"
                style={{
                  background: 'radial-gradient(circle, rgba(239, 128, 93, 1) 30%,rgba(217, 217, 217, 1) 70%)',height:'1px'
                }}
              >
                {/* Rest of your component */}
              </div>
              <div className="flex flex-col items-center ml-[-40px]">
                <ul>
                  <li className="flex items-center py-2">
                    <div className="bg-[#FFDED3] w-10 h-10 flex items-center justify-center rounded-lg">
                      <FiPhone className="text-[#EF805D] text-3xl" />
                    </div>
                    <span className="ml-4">{studentData ? studentData.parentPhoneNo : ''}</span>
                  </li>
                  <li className="flex items-center py-2">
                    <div className="bg-[#FFDED3] w-10 h-10 flex items-center justify-center rounded-lg">
                      <IoCalendarClearOutline className="text-[#EF805D] text-3xl" />
                    </div>
                    <span className="ml-4">{studentData ? studentData.dob : ''}</span>
                  </li>
                  <li className="flex items-center py-2">
                    <div className="bg-[#FFDED3] w-10 h-10 flex items-center justify-center rounded-lg">
                      <FaLocationDot className="text-[#EF805D] text-3xl" />
                    </div>
                    <span className="ml-4">{studentData ? studentData.location : ''}</span>
                  </li>
                </ul>
              </div>
              <div
                className=" "
                style={{
                  background: 'radial-gradient(circle, rgba(239, 128, 93, 1) 30%, rgba(217, 217, 217, 1) 70%)',height:'1px'
                }}
              >
              </div>
              <div >
                <table className="w-full border-collapse">
                  <tbody>
                    <tr>
                      <td className="py-2">Student Id</td>
                      <td className="py-2 font-poppins text-base font-medium leading-6 text-left text-custom-blue ">: {studentData ? studentData.studentId : ''}</td>
                    </tr>
                    <tr>
                      <td className="py-2">Hostel Id</td>
                      <td className="py-2 font-poppins text-base font-medium leading-6 text-left text-custom-blue ">: {studentData ? studentData.hostelId : ''}</td>
                    </tr>
                    <tr>
                      <td className="py-2">School Bus No</td>
                      <td className="py-2 font-poppins text-base font-medium leading-6 text-left text-custom-blue ">: {studentData ? studentData.schoolBusNo : ''}</td>
                    </tr>
                    <tr>
                      <td className="py-2">Board</td>
                      <td className="py-2 font-poppins text-base font-medium leading-6 text-left text-custom-blue ">: {studentData ? studentData.board : ''}</td>
                    </tr>
                    <tr>
                      <td className="py-2">Gender</td>
                      <td className="py-2 font-poppins text-base font-medium leading-6 text-left text-custom-blue ">: {studentData ? studentData.gender : ''}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                className=" "
                style={{
                  background: 'radial-gradient(circle, rgba(239, 128, 93, 1) 30%, rgba(217, 217, 217, 1) 70%)',height:'1px'
                }}
              >
                {/* Rest of your component */}
              </div>
              <div className="mt-4">
                <div className="text-center ml-2" style={{fontSize:'18px',fontWeight:'800'}}>Class Teacher</div>
                <div className="flex items-center bg-[#6B7DEB] h-12 rounded-3xl">
                  <div className="w-16 h-16 mb-10 ml-5 bg-[#100857] rounded-full flex items-center justify-center">
                    <img src={studentData ? `${apiBaseUrl}${studentData.classTeacherImage}` : ''} alt="Class Teacher" className="w-10 h-10  rounded-full" />
                  </div>
                  <div className="flex items-center justify-evenly w-8/12 text-white text-center font-bold text-lg">
                    <p>{studentData ? studentData.classTeacher : ''}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentProfile;
