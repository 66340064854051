import axios from 'axios';
import { apiBaseUrl } from '../../APIs/endpoints';

// Action Types
export const FETCH_SCHOOLS_SUCCESS = 'FETCH_SCHOOLS_SUCCESS';
export const SAVE_SCHOOL_DETAILS = 'SAVE_SCHOOL_DETAILS';
export const SAVE_USER_DETAILS = 'SAVE_USER_DETAILS';

// Base URL
const BASE_URL = apiBaseUrl + '/api';

// Action Creators
export const fetchSchoolsSuccess = (schools) => ({
  type: FETCH_SCHOOLS_SUCCESS,
  payload: schools,
});

export const saveSchoolDetails = (school) => ({
  type: SAVE_SCHOOL_DETAILS,
  payload: school,
});

export const saveUserDetails = (user) => ({
  type: SAVE_USER_DETAILS,
  payload: user,
});

// Thunk Actions
export const fetchSchools = () => async (dispatch) => {
  try {
    const response = await axios.get(`${BASE_URL}/schools/`);
    dispatch(fetchSchoolsSuccess(response.data));
  } catch (error) {
    console.error('Error fetching schools:', error);
  }
};

export const submitAllDetails = (school, user, navigate) => async (dispatch) => {
  try {
    await axios.post(`${BASE_URL}/schools/`, school);
    await axios.post(`${BASE_URL}/profiles/`, user);
    navigate('/dashboard');
  } catch (error) {
    console.error('Error submitting details:', error);
  }
};

// Separate API utility functions
export const addSchool = async (school) => {
  try {
    const response = await axios.post(`${BASE_URL}/schools/`, school);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addUser = async (profile) => {
  try {
    const response = await axios.post(`${BASE_URL}/profiles/`, profile);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchUser = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/profiles/`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
