import React, { useEffect } from "react";
import {
    Card,
    Typography,
    List,
    ListItem,
    ListItemPrefix,
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import {
    AcademicCapIcon
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { FaUserTie, FaSuitcase, FaFileContract, FaUniversity, FaHome, FaBus } from "react-icons/fa";
import { AiOutlineComment } from "react-icons/ai";
import { IoLibraryOutline } from "react-icons/io5";
import { Link, useLocation, useNavigate } from "react-router-dom";

export function MultiLevelSidebar() {
    const [open, setOpen] = React.useState(0);
    const location = useLocation();
    const navigate = useNavigate();
    const getRouteName = location.pathname;

    useEffect(() => {
        // Redirect to dashboard on initial load or when logged in
        if (getRouteName === '/' || getRouteName === '/login') {
            navigate('/dashboard');
        }

        // Determine which accordion should be open based on the route
        if (getRouteName.includes('/dashboard')) {
            setOpen(0); // Dashboard is not an accordion anymore
        } else if (getRouteName.includes('/students')) {
            setOpen(1); // Open Students section
        } else if (getRouteName.includes('/teachers')) {
            setOpen(2); // Open Teachers section
        } else if (getRouteName.includes('/academic-management')) {
            setOpen(3); // Open Academic Management section
        } else if (getRouteName.includes('/communication')) {
            setOpen(4); // Open Communication section
        } else if (getRouteName.includes('/transport')) {
            setOpen(5); // Open Transport section
        } else {
            setOpen(0); // Default to Dashboard
        }
    }, [getRouteName, navigate]);

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };

    return (
        <Card className="h-[calc(100vh-2rem)] w-full max-w-[20rem] shadow-none shadow-blue-gray-900/5">
            <List>
                <ListItem className={`p-0 ${getRouteName.includes('/dashboard') ? 'bg-blue-gray-50' : ''}`}>
                    <Link to="/dashboard" className="flex items-center w-full p-3">
                        <ListItemPrefix>
                            <FaHome className="h-5 w-5" />
                        </ListItemPrefix>
                        <Typography color="blue-gray" className="mr-auto font-normal">
                            Dashboard
                        </Typography>
                    </Link>
                </ListItem>

                <Accordion
                    open={open === 1}
                    icon={
                        <ChevronDownIcon
                            strokeWidth={2.5}
                            className={`mx-auto h-4 w-4 transition-transform ${open === 1 ? "rotate-180" : ""}`}
                        />
                    }
                >
                    <ListItem className="p-0" selected={open === 1}>
                        <AccordionHeader onClick={() => handleOpen(1)} className="border-b-0 p-3">
                            <ListItemPrefix>
                                <AcademicCapIcon className="h-5 w-5" />
                            </ListItemPrefix>
                            <Typography color="blue-gray" className="mr-auto font-normal">
                                Students
                            </Typography>
                        </AccordionHeader>
                    </ListItem>
                    <AccordionBody className="py-1">
                        <List className="p-0">
                            <ListItem className={`sub-list-item ${getRouteName.includes('/students/overview') && 'sub-list-item-active'}`}>
                                <Link to="/students/overview" className="w-full">Overview</Link>
                            </ListItem>
                            <ListItem className={`sub-list-item ${getRouteName.includes('/students/attendance') && 'sub-list-item-active'}`}>
                                <Link to='/students/attendance'>Attendance</Link>
                            </ListItem>
                            <ListItem className="sub-list-item">
                                <div>Academic Records</div>
                            </ListItem>
                            <ListItem className={`sub-list-item ${getRouteName.includes('/students/admission') && 'sub-list-item-active'}`}>
                                <Link to="/students/admission" className="w-full">Admission</Link>
                            </ListItem>
                            <ListItem className="sub-list-item">
                                <div>Health Record</div>
                            </ListItem>
                        </List>
                    </AccordionBody>
                </Accordion>

                <Accordion open={open === 2}
                    icon={<ChevronDownIcon
                        strokeWidth={2.5} className={`mx-auto h-4 w-4 transition-transform ${open === 2 ? "rotate-180" : ""}`} />}
                >
                    <ListItem className="p-0" selected={open === 2}>
                        <AccordionHeader onClick={() => handleOpen(2)} className="border-b-0 p-3">
                            <ListItemPrefix>
                                <FaUserTie className="h-5 w-5" />
                            </ListItemPrefix>
                            <Typography color="blue-gray" className="mr-auto font-normal">
                             Teachers
                            </Typography>
                        </AccordionHeader>
                    </ListItem>
                    <AccordionBody className="py-1">
                        <List className="p-0">
                            <ListItem className={`sub-list-item ${getRouteName.includes('/teachers/overview') && 'sub-list-item-active'}`}>
                                <Link to="/teachers/overview" className="w-full">Overview</Link>
                            </ListItem>
                            <ListItem className="sub-list-item">
                                <Link to='#'>Attendance</Link>
                            </ListItem>
                            <ListItem className="sub-list-item">
                                <div>Schedule</div>
                            </ListItem>
                            <ListItem className={`sub-list-item ${getRouteName.includes('#') && 'sub-list-item-active'}`}>
                                <Link to="/#" className="w-full">Professional Development</Link>
                            </ListItem>
                            <ListItem className="sub-list-item">
                                <div>Performance Appraisal</div>
                            </ListItem>
                        </List>
                    </AccordionBody>
                </Accordion>
                <Accordion open={open === 3}
                    icon={<ChevronDownIcon
                        strokeWidth={2.5} className={`mx-auto h-4 w-4 transition-transform ${open === 3 ? "rotate-180" : ""}`} />}
                >
                    <ListItem className="p-0" selected={open === 3}>
                        <AccordionHeader onClick={() => handleOpen(3)} className="border-b-0 p-3">
                            <ListItemPrefix>
                                <FaUniversity className="h-5 w-5" />
                            </ListItemPrefix>
                            <Typography color="blue-gray" className="mr-auto font-normal">
                                Academic Management
                            </Typography>
                        </AccordionHeader>
                    </ListItem>
                </Accordion>

                <Accordion open={open === 4}
                    icon={<ChevronDownIcon
                        strokeWidth={2.5} className={`mx-auto h-4 w-4 transition-transform ${open === 4 ? "rotate-180" : ""}`} />}
                >
                    <ListItem className="p-0" selected={open === 4}>
                        <AccordionHeader onClick={() => handleOpen(4)} className="border-b-0 p-3">
                            <ListItemPrefix>
                                <AiOutlineComment className="h-5 w-5" />
                            </ListItemPrefix>
                            <Typography color="blue-gray" className="mr-auto font-normal">
                                Communication
                            </Typography>
                        </AccordionHeader>
                    </ListItem>
                </Accordion>

                <Accordion open={open === 5}
                    icon={<ChevronDownIcon
                        strokeWidth={2.5} className={`mx-auto h-4 w-4 transition-transform ${open === 5 ? "rotate-180" : ""}`} />}
                >
                    <ListItem className="p-0" selected={open === 5}>
                        <AccordionHeader onClick={() => handleOpen(5)} className="border-b-0 p-3">
                            <ListItemPrefix>
                                <FaBus className="h-5 w-5" />
                            </ListItemPrefix>
                            <Typography color="blue-gray" className="mr-auto font-normal">
                                Transport
                            </Typography>
                        </AccordionHeader>
                    </ListItem>
                </Accordion>

                <ListItem>
                    <ListItemPrefix>
                        <FaSuitcase className="h-5 w-5" />
                    </ListItemPrefix>
                    <div>HR</div>
                </ListItem>

                <ListItem>
                    <ListItemPrefix>
                        <IoLibraryOutline className="h-5 w-5" />
                    </ListItemPrefix>
                    <div>Library Management</div>
                </ListItem>

                <ListItem>
                    <ListItemPrefix>
                        <FaFileContract className="h-5 w-5" />
                    </ListItemPrefix>
                    <div>Analytics & Reports</div>
                </ListItem>
            </List>
        </Card>
    );
}
