import React from 'react';

const Header = () => {
  return (
    <header className="w-full h-16 flex items-center" style={{ backgroundColor: '#EF805D' }}>
      <div className="container mx-0 px-5 flex justify-between items-center">
        <h1 className="text-2xl font-bold font-poppins text-white" style={{marginLeft:'3%'}}>
          School Registration
        </h1>
        <button className="block sm:hidden text-white" aria-label="menu">
          <svg 
            className="w-6 h-6" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24" 
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
          </svg>
        </button>
      </div>
    </header>
  );
};

export default Header;
