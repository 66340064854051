// src/service/reducers/schoolReducer.js
import { FETCH_SCHOOLS_SUCCESS, SAVE_SCHOOL_DETAILS, SAVE_USER_DETAILS } from '../actions/schoolActions';

const initialState = {
  schools: [],
  school: {}, // Ensure initial state for school is an empty object
  user: {},
};

const schoolReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SCHOOLS_SUCCESS:
      return {
        ...state,
        schools: action.payload,
      };
    case SAVE_SCHOOL_DETAILS:
      return {
        ...state,
        school: action.payload,
      };
    case SAVE_USER_DETAILS:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};

export default schoolReducer;
