import React, { useState } from 'react';
import './adminBar.scss';
import { FaRegBell, FaAngleDown } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

const AdminBar = (props) => {
    const user = localStorage.getItem("user");
    const navigate = useNavigate();
    const [dropdownVisible, setDropdownVisible] = useState(false); // State for dropdown visibility

    const handleLogout = () => {
        localStorage.removeItem("user");
        navigate("/logout");
    };

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible); 
    };

    return (
        <div className='admin-bar-container'>
            <div className='selected-section-title ml-6'>
                {props.title}
            </div>
            <div className='admin-right-section'>
                {/* <div className='notification-icon'>
                    <FaRegBell />
                </div> */}
                <div className='user-section' onClick={toggleDropdown}> {/* Added onClick to user-section */}
                    <div className='user-image'>
                        <img src='/images/Student.png' alt='user' />
                    </div>
                    <div className='user-details'>
                        <div className='user-name'>{user}</div>
                        <div className='user-type'>Admin</div>
                    </div>
                    <FaAngleDown />
                    {dropdownVisible && ( // Conditional rendering of dropdown menu
                        <div className='dropdown-menu'>
                            <div className='dropdown-item' onClick={() => navigate('/support')}>Support</div>
                            <div className='dropdown-item' onClick={handleLogout}>Logout</div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default AdminBar;
