import React, { useState , useEffect} from 'react';
import axios from 'axios';
import { apiBaseUrl as baseEndpoint } from '../../../APIs/endpoints';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';


const ParentForm = (props) => {
    function backtostudent() {
        props.getStudReg(true);
    }
    const navigate = useNavigate();
    const location = useLocation();
 
    useEffect(() => {
        const token = localStorage.getItem('access_token'); 
    
        if (!token) { 
            navigate('/login'); 
        }
    
        const editPath = '/students/edit';
    
        // Clear localStorage when navigating away from the edit page
        if (!location.pathname.startsWith(editPath)) {
            // localStorage.removeItem('academic_Details');
            // localStorage.removeItem('stud_info');
            // localStorage.removeItem('parent_info');
            // localStorage.removeItem('documents');
        }
    }, [navigate, location.pathname]);
    
    
    const defaultParentData = {
        first_name: '',
        middle_name: '',
        last_name: '',
        relation: '',
        alt_mobile: '',
        mobile: '',
        email_id: '',
        qualification: '',
        login_access: '',
        languages_known: '',
        id: null,
        student: null,
    };

    const [formData, setFormData] = useState(() => {
        const savedData = localStorage.getItem("parent_info");
        return savedData ? JSON.parse(savedData) : defaultParentData;
    });

    const [errors, setErrors] = useState({
        first_name: '',
        last_name: '',
        mobile: '',
        relation: '',
        login_access: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    }

    const handleSelectChange = (event, field) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [field]: event.target.value
        }));
    }

    const validateForm = () => {
        const newErrors = {};
        let valid = true;

        const firstName = String(formData.first_name).trim();
        const lastName = String(formData.last_name).trim();
        const mobile = String(formData.mobile).trim();
        const relation = String(formData.relation).trim();
        const login_access = String(formData.login_access).trim();


        if (!firstName) {
            newErrors.first_name = "First Name is required";
            valid = false;
        }

        if (!lastName) {
            newErrors.last_name = "Last Name is required";
            valid = false;
        }

        newErrors.mobile = [];
        if (!mobile) {
            newErrors.mobile.push("Mobile Number is required")
            valid = false;
        } else if (!/^\d{10}$/.test(mobile)){
            newErrors.mobile.push("Mobile Number must be exactly 10 digits");
            valid = false;
        }
        
        if (!relation) {
            newErrors.relation = "Relation is required";
            valid = false;
        }

        if (!login_access) {
            newErrors.login_access = 'Access to login is required.';
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    }



    function handleSubmit(event) {
        event.preventDefault();

        if (!validateForm()) {
            return; // Stop form submission if validation fails
        }

        const student_id = JSON.parse(localStorage.getItem("stud_info"));

        formData.student = student_id.id;
        const endpoint = formData.id
            ? `${baseEndpoint}/api/students/${student_id.id}/parents/${formData.id}/`
            : `${baseEndpoint}/api/students/${student_id.id}/parents/`;
        const method = formData.id ? 'PUT' : 'POST';

        axios({
            method,
            url: endpoint,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
                "Authorization": `Bearer ${localStorage.getItem('access_token')}`
            }
        })
        .then(response => {
            localStorage.setItem('parent_info', JSON.stringify(response.data));
            toast.success("Parent information submitted successfully!");
    
            // Delay navigation to ensure toast message is displayed
            setTimeout(() => {
                props.getAcademicsForm(true, 1, '1');
            }, 1000); // Adjust the delay as needed
        })
        .catch(err => {
            const errorMessage = err.response && err.response.data 
                ? `Error: ${err.response.status} - ${err.response.statusText}\n${JSON.stringify(err.response.data, null, 2)}`
                : "An unexpected error occurred. Please try again later.";
            toast.error(errorMessage);
        });
    }

    function saveAndExit(event) {
        event.preventDefault();

        if (!validateForm()) {
            return; // Stop form submission if validation fails
        }

        const student_id = JSON.parse(localStorage.getItem("stud_info"));

        formData.student = student_id.id;
        const endpoint = formData.id
            ? `${baseEndpoint}/api/students/${student_id.id}/parents/${formData.id}/`
            : `${baseEndpoint}/api/students/${student_id.id}/parents/`;
        const method = formData.id ? 'PUT' : 'POST';

        axios({
            method,
            url: endpoint,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
                "Authorization": `Bearer ${localStorage.getItem('access_token')}`
            }
        })
        .then(response => {
            localStorage.setItem('parent_info', JSON.stringify(response.data));
            toast.success("Parent information saved successfully!");
    
            // Delay navigation to ensure toast message is displayed
            setTimeout(() => {
                localStorage.removeItem('academic_Details');
                localStorage.removeItem('stud_info');
                localStorage.removeItem('parent_info');
                localStorage.removeItem('documents');
                navigate(-1);
            }, 1000); // Adjust the delay as needed
        })
        .catch(err => {
            const errorMessage = err.response && err.response.data 
                ? `Error: ${err.response.status} - ${err.response.statusText}\n${JSON.stringify(err.response.data, null, 2)}`
                : "An unexpected error occurred. Please try again later.";
            toast.error(errorMessage);
        });
    }

    return (
        <div className="w-full flex items-center justify-center">
            <form onSubmit={handleSubmit} className="w-full max-w-3xl">
                <div className="mb-5 flex items-center justify-between">
                    <h3 className="font-poppins text-2xl font-bold leading-9 text-left text-[#303972]">
                    {localStorage.getItem('parent_info') ? 'Update Parent Details' : 'Parent Details'}
                    </h3>
                </div>
                <div className="mb-5 flex justify-between">
                    <div className="pr-5 w-1/2">
                        <div className="mb-4">
                            <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor='first_name'>First Name *</label>
                            <input
                                type="text"
                                placeholder="Enter text here"
                                value={formData.first_name}
                                onChange={handleChange}
                                name='first_name'
                                className="w-full h-12 border border-gray-300 rounded px-2"
                            />
                           {errors.first_name && <p className="text-red-500 text-sm">{errors.first_name}</p>}

                        </div>
                        <div className="mb-4">
                            <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor='last_name'>Last Name *</label>
                            <input
                                type="text"
                                placeholder="Enter text here"
                                value={formData.last_name}
                                onChange={handleChange}
                                name='last_name'
                                className="w-full h-12 border border-gray-300 rounded px-2"
                            />
                             {errors.last_name && <p className="text-red-500 text-sm">{errors.last_name}</p>}
                        </div>
                        <div className="mb-5">
                            <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor='mobile'>Mobile Number *</label>
                            <input
                                type="tel"
                                placeholder="Enter text here"
                                value={formData.mobile}
                                onChange={handleChange}
                                name='mobile'
                                className="w-full h-12 border border-gray-300 rounded px-2"
                            />
                             {errors.mobile && <p className="text-red-500 text-sm">{errors.mobile}</p>}
                        </div>
                        <div className="mb-5">
                            <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor='alt_mobile'>Alternate Mobile Number</label>
                            <input
                                type="tel"
                                placeholder="Enter text here"
                                value={formData.alt_mobile}
                                onChange={handleChange}
                                name='alt_mobile'
                                className="w-full h-12 border border-gray-300 rounded px-2"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor='login_access'>
                                Access to Parent Login *
                            </label>
                            <select
                                name='login_access'
                                onChange={(e) => handleSelectChange(e, 'login_access')}
                                value={formData.login_access}
                                className="w-full h-12 bg-white text-gray-700 border border-gray-300 rounded-lg px-2"
                            >
                                <option value="">Select</option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                            {errors.login_access && <p className="text-red-500 text-sm">{errors.login_access}</p>}

                        </div>
                    </div>
                    <div className="pl-5 w-1/2">
                      <div className="mb-4">
                            <label className="font-poppins text-lg font-semibold  text-[#303972]" htmlFor='middle_name'>Middle Name</label>
                            <input
                                type="text"
                                placeholder="Enter text here"
                                onChange={handleChange}
                                value={formData.middle_name}
                                name='middle_name'
                                className="w-full h-12 border border-gray-300 rounded px-2"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor='relation'>Relation *</label>
                            <select
                                name='relation'
                                onChange={(e) => handleSelectChange(e, 'relation')}
                                value={formData.relation}
                                className="w-full h-12 bg-white text-gray-700 border border-gray-300 rounded-lg px-2"
                            >
                                <option value="">Select</option>
                                <option value="father">Father</option>
                                <option value="mother">Mother</option>
                                <option value="other">Other</option>
                            </select>
                            {errors.relation && <p className="text-red-500 text-sm">{errors.relation}</p>}

                        </div>
                        <div className="mb-5">
                            <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor='email_id'>Email</label>
                            <input
                                type="email"
                                placeholder="Enter text here"
                                value={formData.email_id}
                                onChange={handleChange}
                                name='email_id'
                                className="w-full h-12 border border-gray-300 rounded px-2"
                            />
                        </div>
                        <div className="mb-5">
                            <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor='qualification'>Qualification</label>
                            <input
                                type="text"
                                placeholder="Enter text here"
                                value={formData.qualification}
                                onChange={handleChange}
                                name='qualification'
                                className="w-full h-12 border border-gray-300 rounded px-2"
                            />
                        </div>
                        <div className="mt-2">
                            <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor='languages_known'>Languages Known </label>
                            <input
                                type="text"
                                placeholder="Enter text here"
                                value={formData.languages_known}
                                onChange={handleChange}
                                name='languages_known'
                                className="w-full h-12 border border-gray-300 rounded px-2"
                            />
                        </div>
                    </div>
                </div>
                <div className='flex justify-end'>
                    <div className="button-container">
                        <button
                            type="button"
                            className="bg-gray-300 text-black px-4 py-2 rounded mr-2 button"
                            onClick={backtostudent}
                        >
                            Back
                        </button>
                        {/* <button
                            type="submit"
                            className="bg-blue-600 text-white px-4 py-2 rounded button-active"
                        >
                            {formData.id ? 'Update' : 'Next'}
                        </button> */}
                        {localStorage.getItem('parent_info') ? (
                            <>
                                <button type="submit"  className="bg-blue-600 text-white px-4 py-2 rounded button-active">Update</button>
                                <button type="button" onClick={saveAndExit} className="bg-blue-600 text-white px-4 py-2 rounded button-active">Save and Exit</button>
                            </>
                        ) : (
                            <>
                                <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded button-active">Next</button>
                            </>
                        )}

                    </div>
                </div>
            </form>
            <div>
                {/* Other components */}
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />

            </div>
        </div>
    );
};

export default ParentForm;
