import React, { useEffect, useState } from "react";
import Filter from "./Filter";
import Cards from "./Cards";
import StuList from "../../StudentsList/StuList/StuList";
import { Breadcrumbs } from "@material-tailwind/react";
import { getApiResponse } from "../../../APIs/getApiResponse";
import { endpoints } from "../../../APIs/endpoints";
import { useNavigate } from 'react-router-dom';
import { handleError } from "../../../APIs/apiUtils";

function Overview() {
    const [showList, setShowList] = useState(false);
    const [filtersObj, setFiltersObj] = useState({});
    const [cardsData, setCardsData] = useState([]);
    const [studentsList, setStudentsList] = useState([]);
    const [classesList, setClassesList] = useState([]);
    const [sectionList, setSectionList] = useState([]);
    const [selectedClassValue, setselectedClassValue] = useState('');
    const [selectedSectionValue, setSelectedSectionValue] = useState('');
    const [clearFilters, setClearFilters] = useState(false);
    const [schoolId, setSchoolId] = useState(1);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('access_token'); 
    
        if (!token) { 
          navigate('/login'); 
        }
      }, [navigate]); 
    
    const getOverviewData = async () => {
        try {
            const url = `${endpoints.OVERVIEW}?school_id=${schoolId}`;
            const resp = await getApiResponse(url);
            console.log(resp,"dfwe")
            if (resp.status === 200) {
                setCardsData(resp.data || []);

                if (resp.data?.length) {
                    const classes = [];
                    const classSections = {};
                    resp.data.forEach(item => {
                        classes.push(item.name);
                        classSections[item.name] = [...item.sections];
                    });

                    setClassesList(classes);
                }
            } else {
                setCardsData([]);
                if (resp.status === 401) {
                    navigate("/login"); // Redirect to login
                }

                setError(handleError({ response: { status: resp.status } }));
            }
        } catch (error) {
            const { errorMessage, statusCode } = handleError(error);
            setCardsData([]);
            // Redirect to error page with error message
            if (error.response.status == 401){
                navigate("/login");
                return 1;
            }
            navigate('/error', { 
                state: { errorMessage }
            });
        }
    };

    const getStudentsList = async (params) => {
        try {
            const { searchBy, year, name, className, section } = params;
            let optionalParams = '';
            if (searchBy) {
                optionalParams = `searchBy=${searchBy}&`;
            }
            if (year) {
                optionalParams = optionalParams.length ? optionalParams + `year=${year}&` : `year=${year}&`;
            }
            const endpointWithParams = `${endpoints.STUDENTS_LIST}?${optionalParams}grade=${className || name}&section=${section}`;
            const resp = await getApiResponse(endpointWithParams);

            if (resp.status === 200) {
                setStudentsList(resp.data || []);
                setShowList(true);
            } else {
                if (resp.status === 401) {
                    navigate("/login"); // Redirect to login
                }
                setError(handleError(resp.status));  // Use handleError function here
            }
        } catch (error) {
            const { errorMessage, statusCode } = handleError(error);
            
            // Redirect to error page with error message
            navigate('/error', { 
                state: { errorMessage }
            });
        }
    };

    useEffect(() => {
        getOverviewData();
    }, []);

    const getSectionList = (data) => {
        console.log(data);
        setShowList(true);
        setselectedClassValue(data?.name);
        setSelectedSectionValue(data?.section);
        getStudentsList(data);
    }

    const getClassSelection = val => {
        const sections = cardsData.find(item => item.name === val)?.sections || [];
        setSectionList(sections)
    };

    const getFilterInputs = data => {
        setFiltersObj(data);
        getStudentsList(data);
        setClearFilters(false);
    }

    useEffect(() => {
        console.log(filtersObj);
    }, [filtersObj])

    return (
        <div>
            <Breadcrumbs>
                <div onClick={() => {
                    setShowList(false); 
                    setClearFilters(true);
                    setSelectedSectionValue(null);
                    setselectedClassValue(null);
                }}>Classes</div>
                {showList && <div>Students</div>}
            </Breadcrumbs>
            <Filter
                classesList={classesList}
                selectedClassValue={selectedClassValue}
                sectionsList={sectionList}
                selectedSectionValue={selectedSectionValue}
                getClassSelection={getClassSelection}
                getFilterInputs={getFilterInputs}
                clearFilters={clearFilters}
            />
            {error ? (
                <div className="text-red-500 text-center">{error}</div>
            ) : (
                <>
                    {!showList ? (
                        !cardsData.length ? (
                            <div className="text-gray-500 text-center">No data available.</div>
                        ) : (
                            <Cards cardsData={cardsData} getSectionList={getSectionList} />
                        )
                    ) : (
                        !studentsList.length ? (
                            <div className="text-gray-500 text-center">No students found.</div>
                        ) : (
                            <StuList studentsList={studentsList} />
                        )
                    )}
                </>
            )}
        </div>
    );
}

export default Overview;
