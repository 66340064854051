import React, { useState } from "react";
import { FaCheckCircle, FaUpload, FaFileAlt } from "react-icons/fa";
import axios from 'axios';
import { apiBaseUrl as baseEndpoint } from '../../../APIs/endpoints';

const DocumentsSection = ({ documents, studentId }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadedDocuments, setUploadedDocuments] = useState(documents);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState(null);

  // Handle file selection
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // Handle file upload
  const handleUpload = async (docType, docName) => {
    if (!selectedFile) {
      console.log("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("document", selectedFile);
    formData.append("doc_type", docType);  
    formData.append("doc_name", docName);  
    formData.append("student", studentId); 

    try {
      setUploading(true);
      const response = await axios.post(`${baseEndpoint}/api/students/${studentId}/documents/`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      
      // Update the status of the uploaded document in state
      setUploadedDocuments(prevDocs => 
        prevDocs.map(doc => 
          doc.name === docName ? { ...doc, status: 'added' } : doc
        )
      );
    } catch (error) {
      console.error("Error during file upload:", error.response ? error.response.data : error.message);
    } finally {
      setUploading(false);
      setSelectedFile(null);
    }
  };

  const handleViewDocument = (doc) => {
    const pdfUrl = `${baseEndpoint}${doc.path}`; 
    console.log(pdfUrl);
    setSelectedDocumentUrl(pdfUrl); 
    setShowPopup(true);
  };

  return (
    <div className="bg-white p-8 rounded-3xl shadow-lg max-w-4xl mx-auto">
      <h2 className="text-3xl font-bold mb-6 text-gray-800 border-b pb-3">Student Documents</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {uploadedDocuments.map((doc) => (
          <div key={doc.type} className="bg-gray-50 p-5 rounded-xl flex flex-col justify-between items-center transition-all duration-300 hover:shadow-md">
            <div className="flex flex-col items-center mb-4">
              <FaFileAlt className="text-gray-400 text-2xl mb-2" />
              <h3 className="font-semibold text-lg text-gray-700">{doc.name}</h3>
              {doc.status === "added" && <p className="text-sm text-gray-500 mt-1">Added on: {doc.date}</p>}
            </div>

            {doc.status === "added" && (
              <button
                onClick={() => handleViewDocument(doc)}
                className="flex items-center bg-[#EF805D] text-white px-3 py-1 rounded-full mb-2"              >
               Preview
              </button>
            )}
          </div>
        ))}
      </div>
      {showPopup && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-8 rounded-3xl shadow-lg max-w-4xl mx-auto">
            <embed src={selectedDocumentUrl} type="application/pdf" width="100%" height="500" />
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded-full flex items-center hover:bg-blue-600 transition-colors duration-300 mt-4"
              onClick={() => setShowPopup(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentsSection;
