import React from "react";
import './stepper.scss';

const Stepper = (props) => {


    return (
        <div className="sis-form-stepper-container" style={{backgroundColor:'#feede5', height:'100%'}}>
            <ul className="stu"   >
                <li className="stu1 active">
                    <div className="step-count">1</div>
                    <div className="step-name">Personal Information</div>
                </li>
                <li className={`stu1 ${props.employment}`}>
                    <div className="step-count">2</div>
                    <div className="step-name">Employment Info</div>
                </li>

                <li className={`stu1 ${props.Experience}`}>
                    <div className="step-count">3</div>
                    <div className="step-name">Experience Details</div>
                </li>
                <li className={`stu1 ${props.Documents}`}>

                    <div className="step-count">4</div>
                    <div className="step-name">Documents</div>
                </li>
                <img 
                className="stepper-image"
                src="/images/stepper_image.png"
                alt="nature image"
                style={{marginBottom:'50%' , width:'150%', backgroundColor:'#feede5', paddingLeft:"10%" , marginTop:"15%"}}
            />
            </ul  >

        </div>
    )
}

export default Stepper;