import React, { useState } from 'react';
import Teacher from './Form';
import TeacherInfo from './TeacherInformation/index2';
import TeacherExp from './TeacherExperience/index3';
import Stepper from './Stepper';
import Documents from './Documents/index4';
import { Alert } from '@material-tailwind/react';


const Index = (props) => {
    const [showTeacher, setShowTeacher] = useState(true);
    const [showTeacherInfo, setShowTeacherInfo] = useState(false);
    const [showTeacherExp, setShowTeacherExp] = useState(false);
    const [showDocuments, setShowDocuments]   = useState(false);
    const [showAlert, setAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");

    const [getEmploymentStepper ,setEmploymentStepper]=useState()
    const [getExprienceStepper,setExprienceStepper]=useState()
    const [getDocumentsStepper,setdocmentsStepper]=useState()

    function AlertDefault() {   
        return <Alert  onClose={() => setAlert(false)} style={{position: "absolute", top: "50px", right: "0px", width: "50%",}}>{alertMsg}</Alert>;
    }

    // Define the navigation functions
    const getTeacher = () => {
        setShowTeacher(true);
        setShowTeacherInfo(false);
        setShowTeacherExp(false);
        setShowDocuments(false);
        setEmploymentStepper()
        setExprienceStepper()
        setdocmentsStepper()
    };

    const getTeacherInfo = (data) => {
        setShowTeacher(false);
        setShowTeacherInfo(true);
        setShowTeacherExp(false);
        setShowDocuments(false);
        console.log(data)
        setEmploymentStepper("active")
        setExprienceStepper()
        setdocmentsStepper()
    };

    const getTeacherExp = () => {
        setShowTeacher(false);
        setShowTeacherInfo(false);
        setShowTeacherExp(true);
        setShowDocuments(false);

        setEmploymentStepper("active")
        setExprienceStepper("active")
        setdocmentsStepper()
    };

    const getDocuments = () => {
        setShowTeacher(false);
        setShowTeacherInfo(false);
        setShowTeacherExp(false);
        setShowDocuments(true)

        setEmploymentStepper("active")
        setExprienceStepper("active")
        setdocmentsStepper("active")
    };

    return (
       
            <div className='m-4'>
                <div style={{
                    background: "#ed805f",
                    color: "white",
                    padding: "12px",
                    paddingLeft: "35px",
                    borderRadius: "4px",
                    fontSize: "20px"
                }}><b>Add Teacher</b>
                </div>

                <div style={{ display: "flex" }}>
                    <Stepper employment={getEmploymentStepper}  Experience={getExprienceStepper} Documents={getDocumentsStepper}/>

                    {/* <Teacher/> */}
                    {showTeacher && <Teacher getTeacherInfo={getTeacherInfo} setAlert={setAlert} setAlertMsg={setAlertMsg} />}
                    {showTeacherInfo && <TeacherInfo getTeacher={getTeacher} getTeacherExp={getTeacherExp} setAlert={setAlert} setAlertMsg={setAlertMsg}/>}
                    {showTeacherExp && <TeacherExp getTeacherInfo={getTeacherInfo} getDocuments={getDocuments} setAlert={setAlert} setAlertMsg={setAlertMsg} />}
                    {showDocuments && <Documents getTeacherExp={getTeacherExp} setAlert={setAlert} setAlertMsg={setAlertMsg}/>}
                    
                </div>
                {showAlert && <AlertDefault />}
            </div>
        )
    }
    
    

export default Index;
