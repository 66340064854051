import React, { useState, useEffect } from 'react';
import './form.scss';
import axios from 'axios';
import { apiBaseUrl as baseEndpoint } from '../../../APIs/endpoints';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

const Teacher = (props) => {

  const defaultData = {
    first_name: '',
    last_name: '',
    date_of_birth: '',
    alternate_mobile_number: '',
    upload_photo: null,
    address: '',
    middel_name: '',
    gender: '',
    mobile_number: '',
    aadhar_number: '',
    mail_id: '',
    languages_known: '',
    id: null,
  };

  const [formData, setFormData] = useState(defaultData);
  const [photoPreview, setPhotoPreview] = useState(null);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('access_token'); 

    if (!token) { 
      navigate('/login'); 
    }
  }, [navigate]);

  // Fetch teacher data from localStorage on component mount
  useEffect(() => {
    const storedTeacher = localStorage.getItem('teacher');
    if (storedTeacher) {
      const parsedTeacher = JSON.parse(storedTeacher);
  
      // Check if the uploaded photo is a string (URL). If so, reset to null.
      if (typeof parsedTeacher.upload_photo === 'string') {
        parsedTeacher.upload_photo = null;
      }
  
      setFormData(parsedTeacher);
      if (parsedTeacher.upload_photo) {
        setPhotoPreview(parsedTeacher.upload_photo); 
      }
    }
  }, []);
  
  

  const handleUploadPhoto = (event) => {
    const file = event.target.files[0];
    if (file) {
        setFormData({ ...formData, upload_photo: file });
        setPhotoPreview(URL.createObjectURL(file));
    }
};
  const handleChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      [event.target.name]: event.target.value,
    }));
  };

  const updateGender = (val) => {
    setFormData((prevData) => ({
      ...prevData,
      gender: val,
    }));
  };
  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    // Ensure all fields are treated as strings
    const firstName = String(formData.first_name).trim();
    const lastName = String(formData.last_name).trim();
    const aadhar_number = String(formData.aadhar_number).trim();
    const date_of_birth = String(formData.date_of_birth).trim();
    const gender = String(formData.gender).trim();
    const mobile_number = String(formData.mobile_number).trim();
    const upload_photo = formData.upload_photo;
    const address = String(formData.address).trim();
    const mail_id = String(formData.mail_id).trim();
    const alternate_mobile_number = String(formData.alternate_mobile_number).trim();

    if (!firstName) {
        newErrors.first_name = "First Name is required";
        valid = false;
    }
    if (!lastName) {
        newErrors.last_name = "Last Name is required";
        valid = false;
    }
    newErrors.aadhar_number = [];
    if (!aadhar_number) {
        newErrors.aadhar_number.push("Aadhar Number is required");
        valid = false;
    } else if (!/^\d{12}$/.test(aadhar_number)) {
        newErrors.aadhar_number.push("Aadhar Number must be exactly 12 digits");
        valid = false;
    }
    if (newErrors.aadhar_number.length > 0) {
        newErrors.aadhar_number = newErrors.aadhar_number.join(", ");
    }

    // if (!upload_photo) {
    //     newErrors.upload_photo = "Teacher photo is required";
    //     valid = false;
    // } else if (typeof upload_photo !== 'object' || upload_photo.type === 'application/octet-stream/jpg/png') {
    //     newErrors.upload_photo = "The submitted data was not a file.";
    //     valid = false;
    // }
    if (!date_of_birth) {
        newErrors.date_of_birth = "Date Of Birth is required";
        valid = false;
    } else if (new Date(date_of_birth) > new Date()) {
        newErrors.date_of_birth = "Date Of Birth cannot be in the future";
        valid = false;
    }
    if (!gender) {
        newErrors.gender = "Gender is required";
        valid = false;
    }
    newErrors.mobile_number = [];
    if (!mobile_number) {
        newErrors.mobile_number.push("Mobile Number is required")
        valid = false;
    } else if (!/^\d{10}$/.test(mobile_number)){
        newErrors.mobile_number.push("Mobile Number must be exactly 10 digits");
        valid = false;
    }
    if(alternate_mobile_number && !/^\d+$/.test(alternate_mobile_number)) {
        newErrors.alternate_mobile_number = "Alternate Mobile Number must be only numbers";
        valid = false;
    }
    if(!address){
       newErrors.address = "Address is Required";
       valid = false;
    }
    if(!mail_id){
       newErrors.mail_id = "Mail Id is Required";
       valid = false;
    }

    setErrors(newErrors);
    return valid;
};

const handleSubmit = (event) => {
  event.preventDefault();

  if (!validateForm()) {
    return; 
  }

    const formDataToSend = new FormData();

    Object.keys(formData).forEach(key => {
      // Handle 'upload_photo' field separately
      if (key === "upload_photo") {
        // Check if the upload_photo is a file object
        if (formData[key] instanceof File) {
          formDataToSend.append(key, formData[key]);
        } else {
          // If it's not a file, we skip appending it
          console.warn("upload_photo is not a valid File object. Skipping.");
        }
      } else {
        formDataToSend.append(key, formData[key]);
      }
    });

    let endpoint = `${baseEndpoint}/api/teachers/`;
    let method = 'POST';
    if (formData.id) {
      endpoint = `${baseEndpoint}/api/teachers/${formData.id}/`;
      method = 'PUT';
    }

    axios({
      method: method,
      url: endpoint,
      data: formDataToSend,
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
      .then((response) => {
        console.log(response);
        localStorage.setItem('teacher', JSON.stringify(response.data));
        toast.success("Teacher information submitted successfully!");

        setTimeout(() => {
          props.getTeacherInfo(true);
        },1000);
      })
      .catch((err) => {
          const errorMessage = err.response && err.response.data 
              ? `Error: ${err.response.status} - ${err.response.statusText}\n${JSON.stringify(err.response.data, null, 2)}`
              : "An unexpected error occurred. Please try again later.";
          toast.error(errorMessage);
      });
  };


  return (
<div className="w-full flex items-center justify-center pt-5">
  <div className="w-full max-w-3xl">
    <div className="mb-5 flex items-center justify-between">
      <h3 className="text-2xl font-bold" style={{ color: '#303972' }}>
        Teacher Details
      </h3>
    </div>

    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {/* Row 1 */}
      <div className="pr-5">
        <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor="first_name">
          First Name *
        </label>
        <input
          type="text"
          placeholder="Enter text here"
          onChange={handleChange}
          name="first_name"
          value={formData.first_name}
          className="w-full h-12 border border-gray-300 rounded px-2"
        />
        {errors.first_name && (
<p className="text-red-500 text-sm">{errors.first_name}</p>
)}
      </div>
      <div className="pl-5">
        <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor="middel_name">
          Middle Name
        </label>
        <input
          type="text"
          placeholder="Enter text here"
          onChange={handleChange}
          name="middel_name"
          value={formData.middel_name}
          className="w-full h-12 border border-gray-300 rounded px-2"
        />
      </div>

      {/* Row 2 */}
      <div className="pr-5">
        <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor="last_name">
          Last Name *
        </label>
        <input
          type="text"
          placeholder="Enter text here"
          onChange={handleChange}
          name="last_name"
          value={formData.last_name}
          className="w-full h-12 border border-gray-300 rounded px-2"
        />
         {errors.last_name && (
<p className="text-red-500 text-sm">{errors.last_name}</p>
)}
      </div>
      <div className="pl-5">
        <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor="gender">
          Gender *
        </label>
        <select
          className="w-full h-12 border border-gray-300 rounded px-3"
          value={formData.gender}
          onChange={(e) => updateGender(e.target.value)}
        >
          <option value="">Select</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="other">Other</option>
        </select>
        {errors.gender && (
        <p className="text-red-500 text-sm">{errors.gender}</p>
       )}
      </div>

      {/* Row 3 */}
      <div className="pr-5">
        <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor="date_of_birth">
          Date of Birth *
        </label>
        <input
          type="date"
          onChange={handleChange}
          name="date_of_birth"
          value={formData.date_of_birth}
          className="w-full h-12 border border-gray-300 rounded px-2"
        />
         {errors.date_of_birth && (
<p className="text-red-500 text-sm">{errors.date_of_birth}</p>
)}
      </div>
      <div className="pl-5">
        <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor="mobile_number">
          Mobile Number *
        </label>
        <input
          type="tel"
          onChange={handleChange}
          name="mobile_number"
          value={formData.mobile_number}
          placeholder="Enter text here"
          className="w-full h-12 border border-gray-300 rounded px-2"
        />
        {errors.mobile_number && (
<p className="text-red-500 text-sm">{errors.mobile_number}</p>
)}
      </div>

      {/* Row 4 */}
      <div className="pr-5">
        <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor="alternate_mobile_number">
          Alternate Mobile Number
        </label>
        <input
          type="tel"
          placeholder="Enter text here"
          onChange={handleChange}
          name="alternate_mobile_number"
          value={formData.alternate_mobile_number}
          className="w-full h-12 border border-gray-300 rounded px-2"
        />
       {errors.alternate_mobile_number && (
    <p className="text-red-500 text-sm">{errors.alternate_mobile_number}</p>
       )}
      </div>
      <div className="pl-5">
        <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor="aadhar_number">
          Aadhar Number *
        </label>
        <input
          type="tel"
          onChange={handleChange}
          name="aadhar_number"
          value={formData.aadhar_number}
          placeholder="Enter text here"
          className="w-full h-12 border border-gray-300 rounded px-2"
        />
        {errors.aadhar_number && (
    <p className="text-red-500 text-sm">{errors.aadhar_number}</p>
       )}
      </div>
      <div className="mb-4">
            <div className="flex flex-col mt-1 mb-4">
                <label
                    className="font-poppins text-lg font-semibold text-[#303972]"
                >
                    Upload Photo *
                </label>
                <input
                    id="upload_photo"
                    type="file"
                    name="upload_photo"
                    onChange={handleUploadPhoto}
                    className="hidden" 
                />
                <div className="flex items-center">
                    <button
                        type="button"
                        onClick={() => document.getElementById('upload_photo').click()}
                        className={`w-1/2 border border-gray-300 rounded px-4 py-2 text-black focus:outline-none focus:ring-2 focus:ring-opacity-50 ${
                            formData.upload_photo
                                ? 'bg-[#8D8C8C] hover:bg-[#6B6B6B] focus:ring-[#8D8C8C]'
                                : 'bg-[#D9D9D9] hover:bg-[#BFBFBF] focus:ring-[#D9D9D9]'
                        }`}
                    >
                        {formData.upload_photo ? 'Change Photo' : 'Upload Photo'}
                    </button> 
                    {photoPreview && (
                        <img
                            src={photoPreview}
                            // src='public/logo192.png'
                            alt="Photo Preview"
                            className="ml-4 w-10 h-10 object-cover rounded"
                        />
                    )}
                </div>
                {errors.upload_photo && (
                    <p className="text-red-500 text-sm">{errors.upload_photo}</p>
                )}
            </div>
        </div>
      <div className="pl-5">
        <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor="mail_id">
          Mail ID *
        </label>
        <input
          type="email"
          onChange={handleChange}
          name="mail_id"
          value={formData.mail_id}
          placeholder="Enter text here"
          className="w-full h-12 border border-gray-300 rounded px-2"
        />
           {errors.mail_id && (
        <p className="text-red-500 text-sm">{errors.mail_id}</p>
       )}
      </div>

      {/* Row 6 */}
      <div className="pr-5">
        <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor="address">
          Address *
        </label>
        <textarea
          name="address"
          value={formData.address}
          onChange={handleChange}
          placeholder="Enter text here"
          className="w-full border border-gray-300 rounded px-1"
          style={{ height: '150px', resize: 'none', padding: '4px' }}
        />
                   {errors.address && (
        <p className="text-red-500 text-sm">{errors.address}</p>
       )}
      </div>

      {/* Row 7 */}
      <div className="pl-5">
        <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor="languages_known">
          Languages Known
        </label>
        <input
          type="text"
          onChange={handleChange}
          name="languages_known"
          value={formData.languages_known}
          placeholder="Enter text here"
          className="w-full h-12 border border-gray-300 rounded px-2"
        />
      </div>
    </div>

    <div className="flex justify-end mt-5">
      <button
        type="button"
        className="bg-gray-300 text-black px-4 py-2 rounded mr-2 button"
      >
        Back
      </button>
      <button
        type="button"
        onClick={handleSubmit}
        className="bg-[#EF805D] text-white px-4 py-2 rounded button-active"
      >
        Next
      </button>
    </div>
  </div>
  <div>
    {/* Other components */}
    <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
    />

</div>
      </div>
  );
};

export default Teacher;
