import React from 'react';
import ReactApexChart from "react-apexcharts";

const AttentionSpan = ({ chartData }) => {
    const options = {
        chart: {
            type: 'bar',
            height: 200,
            toolbar: {
                show: false
            }
        },
        xaxis: {
            type: 'category',
            categories: ['0-15min', '15-30min', '30-45min']
        },
        yaxis: {
            tooltip: {
                enabled: false
            },
            tickAmount: 3,
            show: true,
            floating: false,
            axisBorder: {
                show: true,
                color: '#FFCECE',
                offsetX: 0,
                offsetY: 0
            },
            axisTicks: {
                show: false
            },
            labels: {
                show: true
            },
            min: 0,
            max: 15,
        },
        grid: {
            show: false, 
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'light',
                type: "vertical",
                shadeIntensity: 0.25,
                gradientToColors: ['#FFB07A'],
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100],
                colors: ['#E6B57A', '#FFB07A']
            },
            colors: ['#FF6D6D'],
        },
        plotOptions: {
            bar: {
                columnWidth: '40%',
                borderRadius: 10
            }
        },
        annotations: {
            yaxis: [
                {
                    y: 0,
                    borderColor: '#FFCECE', 
                    strokeDashArray: 0, 
                    label: {
                        borderColor: '#FFCECE',
                        style: {
                            color: '#FFCECE',
                            background: '#FFCECE',
                        },
                    }
                }
            ]
        }
    };

    const data = [
        chartData?.[0] || 0,
        chartData?.[1] || 0,
        chartData?.[2] || 0
    ];

    const series = [{ data }]; 

    return (
        <div>
            <ReactApexChart options={options} series={series} type="bar" height={250} />
        </div>
    );
};

export default AttentionSpan;
