import React from "react";
import './cards.scss';

const Card = (props) => {
    const backgroundColor = props.backgroundColor;
    const image = props.image;
    const name = props.name;
    const boys = props.boys;
    const girls = props.girls;
    const sections = props.sections;

    const sectionText = sections.map((value, i) => {
        return <div className="letter" key={i} onClick={() => props.getSectionList({ name, section: value })}>{value}</div>
    });
    return (
        <div className="card-container" style={{ background: backgroundColor, marginBottom: '1rem', padding: '2.25rem' }}>
            <div style={{ width: "60%" }}>
                <h3 className="card-title" style={{ fontWeight: 'bold' }}> Class {name}</h3>
                <p className="section-tag" style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>Sections:</p>
                <div style={{ display: "flex" }}>{sectionText}</div>
                <div style={{ display: "flex" }}>
                    <div className="totalStudentsText">Total Girls: <span className="class-count">{girls}</span></div>
                    <div className="totalStudentsText" style={{ marginLeft: "20px" }}>Total Boys: <span className="class-count">{boys}</span></div>
                </div>
            </div>
            <div style={{ width: "40%", position: "relative" }}>
                <img src={image} alt="students with books" style={{ width: "100%", position: "absolute", bottom: "0", height: "105%" }} />
            </div>
        </div>
    )
}

export default Card;