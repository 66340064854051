import React, { useEffect, useState } from 'react';
import { Button, IconButton } from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/16/solid";
import './pagination.scss';

const Index = (props) => {
    const { data = [], getRecords = () => {}, itemsPerPage = 10 } = props;
    const [active, setActive] = useState(1);
    const [pageRange, setPageRange] = useState([1, 3]); // Initially show pages 1 and 3
    const nPages = Math.ceil(data.length / itemsPerPage);
    const pageNumbers = [...Array(nPages + 1).keys()].slice(1);
    const lastIndex = active * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;
    const currRecords = data.slice(firstIndex, lastIndex);

    useEffect(() => {
        data.length && getRecords(currRecords);
    }, [active, data]);

    const getItemProps = (index) => ({
        variant: active === index ? "filled" : "text",
        className: active === index ? "active" : "page-button",
        onClick: () => setActive(index),
    });

    const next = () => {
        if (active === nPages) return;
        setActive(active + 1);


        if (active >= pageRange[1]) {
            setPageRange([pageRange[0] + 3, pageRange[1] + 3]);
        }
    };

    const prev = () => {
        if (active === 1) return;
        setActive(active - 1);

        // Shift the page range back by 3 if necessary
        if (active <= pageRange[0]) {
            setPageRange([pageRange[0] - 3, pageRange[1] - 3]);
        }
    };

    return (
        <div className="pagination-container">
            <div className="pagination-text">
                Showing data {firstIndex + 1} to {firstIndex + currRecords.length} of {data.length} entries
            </div>
            <div className="pagination-buttons">
                <Button
                    variant="text"
                    className="flex items-center gap-3"
                    onClick={prev}
                    disabled={active === 1}
                >
                    <ArrowLeftIcon strokeWidth={3} className="h-4 w-4" />
                </Button>
                <div className="flex items-center gap-3">
                    {/* Show only two page numbers at a time */}
                    {pageNumbers.slice(pageRange[0] - 1, pageRange[1]).map(n => (
                        <IconButton key={n} {...getItemProps(n)}>{n}</IconButton>
                    ))}
                </div>
                <Button
                    variant="text"
                    className="flex items-center gap-3"
                    onClick={next}
                    disabled={active === nPages}
                >
                    <ArrowRightIcon strokeWidth={3} className="h-4 w-4" />
                </Button>
            </div>
        </div>
    );
};

export default Index;
