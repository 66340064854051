import React from 'react';
import { apiBaseUrl } from '../../../APIs/endpoints';


const TeacherPerformance = ({ teacherData = [] }) => {

    if (!teacherData.length) {
        return <div>No teacher data available</div>;
    }

    const teacher = teacherData[0];

    // Calculate the circumference of the circle
    const radius = 45; // Radius of the circular progress
    const circumference = 2 * Math.PI * radius;

    // Calculate the strokeDashoffset based on performance percentage
    const performancePercentage = teacher.overall_participation || 0;
    const strokeDashoffset = circumference - (performancePercentage / 100) * circumference;

    // Function to get the stroke color based on percentage
    const getStrokeColor = (percentage) => {
        if (percentage <= 25) return '#F87171';  
        if (percentage <= 50) return '#FBBF24'; 
        if (percentage <= 75) return '#34D399';  
        return '#60A5FA';                        
    };

    return (
        <div className='p-2'>
            <div className='flex items-start mb-4'>
                <div className='flex-1'>
                    <div className='flex justify-start items-center mb-4'>
                        <h2 className='text-xl font-bold'>Teacher Performance</h2>
                        <span className='text-green-500 border border-green-500 rounded-full px-2 bg-green-100 ml-3'>
                            On Time
                        </span>
                    </div>
                    <div className='flex items-center mb-4'>
                        <img src={teacher ? `${apiBaseUrl}${teacher.teacher_photo}` : ''} alt="teacher" className='w-8 h-8 rounded-full mr-2' />
                        <span className='font-bold'>{teacher.teacher_name}</span>
                    </div>
                    <div className='text-black-500 border border-gray-500 rounded-full px-2 bg-gray-100 inline-block'>
                        #{teacher.performance_tag}
                    </div>
                </div>
                <div className='relative flex flex-col justify-center items-center mb-4'>
                    <svg width="100" height="100">
                        <circle 
                            cx="50" 
                            cy="50" 
                            r={radius} 
                            fill="transparent" 
                            stroke="#FBB29C" 
                            strokeWidth="8"
                        />
                        <circle 
                            cx="50" 
                            cy="50" 
                            r={radius} 
                            fill="transparent" 
                            // stroke={getStrokeColor(teacher.overall_participation)} 
                            stroke ="#FA7C56"
                            strokeWidth="8" 
                            strokeDasharray={circumference} 
                            strokeDashoffset={strokeDashoffset} 
                            style={{ transition: 'stroke-dashoffset 0.5s ease-in-out' }}
                        />
                        <text 
                            x="50" 
                            y="50" 
                            className="text-xl font-bold" 
                            dominantBaseline="middle" 
                            textAnchor="middle" 
                            fill="#34D399"
                        >
                            {teacher.overall_participation || 'N/A'}%
                        </text>
                    </svg>
                    <div className='mt-2'>Overall Performance</div>
                </div>
            </div>
            <div className='flex justify-between'>
                <div className='flex flex-col items-start bg-[#E9F0FF] rounded-lg p-2 mx-1 flex-1 min-w-[150px] max-w-[250px]'>
                    <h3 className='font-bold mb-2 text-sm whitespace-nowrap'>Interaction</h3>
                    <div className='flex justify-between w-full mt-2 items-center'>
                        <span className='text-xl font-semibold'>{teacher.interaction || '0  '}%</span>
                        <div className='flex space-x-1'>
                            <div className='w-1 h-4 bg-green-500 rounded' />
                            <div className='w-1 h-4 bg-green-500 rounded' />
                            <div className='w-1 h-4 bg-green-500 rounded' />
                        </div>
                    </div>
                </div>

                <div className='flex flex-col items-start bg-[#E9F0FF] rounded-lg p-2 mx-1 flex-1 min-w-[150px] max-w-[250px]'>
                    <h3 className='font-bold mb-2 text-sm whitespace-nowrap'>Presenting on Board</h3>
                    <div className='flex justify-between w-full mt-2 items-center'>
                        <span className='text-xl font-semibold'>{teacher.writing || '0'}%</span>
                        <div className='flex space-x-1'>
                            <div className='w-1 h-4 bg-green-500 rounded' />
                            <div className='w-1 h-4 bg-green-500 rounded' />
                            <div className='w-1 h-4 bg-green-500 rounded' />
                        </div>
                    </div>
                </div>
                <div className='flex flex-col items-start bg-[#E9F0FF] rounded-lg p-2 mx-1 flex-1 min-w-[150px] max-w-[250px]'>
                    <h3 className='font-bold mb-2 text-sm whitespace-nowrap'>Moving Around</h3>
                    <div className='flex justify-between w-full mt-2 items-center'>
                        <span className='text-xl font-semibold'>{teacher.walking || '0'}%</span>
                        <div className='flex space-x-1'>
                            <div className='w-1 h-4 bg-green-500 rounded' />
                            <div className='w-1 h-4 bg-green-500 rounded' />
                            <div className='w-1 h-4 bg-green-500 rounded' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeacherPerformance;
