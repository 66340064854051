import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Alert } from "@material-tailwind/react";
import { apiBaseUrl as baseEndpoint } from '../../APIs/endpoints';

const AddSchool = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(localStorage.getItem('school_info')? JSON.parse(localStorage.getItem('school_info')): { name: '', school_code: '', address: '',logo: null, id:null } 
  );
  const [showAlert, setAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleLogoChange = (e) => {
    setFormData({
      ...formData,['logo']: e.target.files[0]
    });
  };

  const handleNextClick = () => {
    console.log(formData)
    var endpoint = `${baseEndpoint}/api/schools/`;
    var method = 'POST'
    if ("school_info" in localStorage) {
        endpoint = `${baseEndpoint}/api/schools/${formData.id}/`;
        method = 'PUT';
    }

    axios({
        method: method,
        url: endpoint,
        data: formData,
        headers: {
          'content-type': 'multipart/form-data'
        }
        
    }).then(response => {
        console.log(response);
        localStorage.setItem('school_info', JSON.stringify(response.data));
        setAlert(true);
        setAlertMsg(`School Data Loaded for:${response.data.name}`);
        navigate('/add-user/'+response.data.id);
    }).catch(err => {
        setAlert(true);
        setAlertMsg(JSON.stringify(err.response.data, undefined, 2));
    })

  };

  function AlertDefault() {   
    return <Alert  onClose={() => setAlert(false)} style={{position: "absolute", top: "50px", right: "0px", width: "50%"}}>{alertMsg}</Alert>;
  }

  return (
    <div className="mt-4" style={{ maxWidth: '70%', marginLeft: '5%' }}>
      <h5 className="text-[#303972] text-2xl font-bold font-poppins leading-9 text-left" style={{ fontWeight: '700' }}>
        School Registration
      </h5>
      <div className="pt-5 grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="w-full md:w-3/4">
          <label htmlFor="formSchoolName" className="text-[#303972] text-lg font-semibold font-poppins leading-5" style={{ fontWeight: '600' }}>
            School Name*
          </label>
          <input
            type="text"
            id="formSchoolName"
            name="name"
            placeholder="Enter School Name"
            className="w-full h-12 border border-gray-300 rounded-lg px-3"
            value={formData.name}
            onChange={handleInputChange}
          />
        </div>
        <div className="w-full md:w-3/4">
          <label htmlFor="formSchoolCode" className="text-[#303972] text-lg font-semibold font-poppins leading-5" style={{ fontWeight: '600' }}>
            School Code
          </label>
          <input
            type="text"
            id="formSchoolCode"
            name="school_code"
            placeholder="Enter School Code"
            className="w-full h-12 border border-gray-300 rounded-lg px-3"
            value={formData.school_code}
            onChange={handleInputChange}
          />
        </div>
        <div className="w-full md:w-3/4">
          <label htmlFor="formAddress" className="text-[#303972] text-lg font-semibold font-poppins leading-5" style={{ fontWeight: '600' }}>
            Address*
          </label>
          <textarea
            id="formAddress"
            name="address"
            rows={3}
            placeholder="Enter Address"
            className="w-full border border-gray-300 rounded-lg px-3 py-2"
            value={formData.address}
            onChange={handleInputChange}
          />
        </div>
        <div className="w-full md:w-3/4">
          <label className="text-[#303972] text-lg font-semibold font-poppins leading-5" style={{ fontWeight: '600' }}>
            School Logo
          </label>
          <input
            type="file"
            name="logo"
            className="w-full border border-gray-300 rounded-lg px-3 py-2"
            onChange={handleLogoChange}
          />

        </div>
      </div>
      <div className="mt-5" style={{ position: 'fixed', bottom: '30%', right: '10%', left: '0', width: '100%', justifyContent: 'flex-end', paddingRight: '28%' }}>
        <div className="text-end">
          <button
            className="mr-2 text-orange-500 border border-orange-500 rounded shadow-none w-40 h-14 text-lg font-medium bg-transparent"
            onClick={() => navigate('/school')}
            style={{ borderRadius: '6px' }}
          >
            Back
          </button>
          <button
            className="text-white bg-[#EF805D] w-40 h-14 text-lg font-medium"
            onClick={handleNextClick}
            style={{ borderRadius: '6px' }}
          >
            Next
          </button>
        </div>
      </div>
      {showAlert && <AlertDefault />}
    </div>
  );
};

export default AddSchool;
