// import { createStore, applyMiddleware } from 'redux';
// import { thunk } from 'redux-thunk'; // Adjusted to use named import
// import { composeWithDevTools } from 'redux-devtools-extension';
// import rootReducer from '../reducers';

// const middleware = [thunk];

// const store = createStore(
//   rootReducer,
//   composeWithDevTools(applyMiddleware(...middleware))
// );
// src/store.js
import { createStore, applyMiddleware, combineReducers } from 'redux';
import {thunk} from 'redux-thunk';
import schoolReducer from '../reducers/schoolsReducer';

const rootReducer = combineReducers({
  school: schoolReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
