import { toast } from 'react-toastify';

export const handleError = (error) => {
    let errorMessage = "An unexpected error occurred.";
    let statusCode = null;

    if (error.response) {
        statusCode = error.response.status;
        switch (statusCode) {
            case 401:
                errorMessage = "Unauthorized. Please log in and try again.";
                break;
            case 403:
                errorMessage = "Forbidden. You do not have permission to access this resource.";
                break;
            case 404:
                errorMessage = "Resource not found.";
                break;
            case 500:
                errorMessage = "Server error. Please try again later.";
                break;
            default:
                errorMessage = "An unexpected error occurred.";
        }
    } else if (error.request) {
        errorMessage = "Network error. Please check your internet connection.";
    } else {
        errorMessage = error.message;
    }

    // Display the error message as a toast notification
    toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    return { errorMessage, statusCode };
};