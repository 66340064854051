import React, { useState, useEffect } from 'react';
import { DateRangePicker } from 'react-date-range';
import { Button } from "@material-tailwind/react";
import { HiOutlineCalendar } from 'react-icons/hi';
import './datePicker.scss';

const DatePicker = ({ getDateRange = () => {}, initialDateRange, initiallySelected = false }) => {
    const [selectionRange, setSelectRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });

    const [showDatePicker, setShowDatePicker] = useState(false);
    const [selectedRange, setSelectedRange] = useState(initiallySelected ? initialDateRange : {});

    const convertDateToLocal = val => {
        const date = new Date(val);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const handleSelect = val => {
        setSelectRange(val.selection);
    }

    useEffect(() => {
        if (initiallySelected && initialDateRange) {
            setSelectedRange(initialDateRange);
            getDateRange(initialDateRange);
        }
    }, [initiallySelected, initialDateRange]);

    const saveSelection = () => {
        const stringifiedDates = {
            startDate: convertDateToLocal(selectionRange?.startDate),
            endDate: convertDateToLocal(selectionRange?.endDate)
        }
        setSelectedRange(stringifiedDates || {});
        getDateRange(stringifiedDates || {});
        setShowDatePicker(false);
    }

    return (
        <div className='date-picker-container'>
            <div>
            <Button
             className="flex items-center gap-3 date-choose-btn" variant='text'
             onClick={() => setShowDatePicker(true)}>
                {
                    Object.keys(selectedRange).length ?
                        <>{selectedRange?.startDate} - {selectedRange?.endDate}</> : 'Choose Date'
                }
                <HiOutlineCalendar />
            </Button>
            </div>
            {showDatePicker && <div className='date-picker'>
                <DateRangePicker
                    ranges={[selectionRange]}
                    onChange={(val)=>handleSelect(val)}
                    rangeColors={['#EF805D']}
                    initialFocusedRange={selectionRange}
                    />
                <div className='date-picker-footer'>
                    <div className='selected-dates'>{convertDateToLocal(selectionRange?.startDate)} - {convertDateToLocal(selectionRange?.endDate)}</div>
                    <Button size='sm' variant='outlined' onClick={() => setShowDatePicker(false)}>Cancel</Button>
                    <Button size='sm' color='#EF805D' className='apply-btn' onClick={()=>saveSelection()}>Apply</Button>
                </div>
            </div>}
        </div>
      )
}

export default DatePicker;
