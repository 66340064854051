import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FiPlusCircle } from "react-icons/fi";
import { apiBaseUrl } from '../../APIs/endpoints';
import { handleError } from '../../APIs/apiUtils';

const SchoolDashboard = () => {
  const [schools, setSchools] = useState([]);
  const navigate = useNavigate();
  const defaultImage = '/images/School Building.png'; // Default image path

  // Fetch schools from backend
  useEffect(() => {
    axios.get(apiBaseUrl+'/api/schools/')
      .then(response => {
        setSchools(response.data);
      })
      .catch(error => {
        const { errorMessage, statusCode } = handleError(error);
        navigate('*', { 
            state: { errorMessage }
        });
    }); 
  }, []);

  const handleAddSchoolClick = () => {
    navigate('/add-school');
  };

  const handleSchoolClick = (id) => {
    navigate(`/schooldetails/${id}`);
  };

  return (
    <div className="my-8 px-16">
      <div className="flex justify-between items-center mb-6" style={{paddingLeft:'3%', paddingRight:'3%'}}>
        <h1 className="font-bold text-3xl">Dashboard</h1>
        <button
          onClick={handleAddSchoolClick}
          className="flex items-center px-4 py-2 text-orange-500 border border-orange-500 rounded shadow-none"
          style={{ 
            marginRight: '8px', 
            width: '161px', 
            height: '50px', 
            fontSize: "16px",
            fontWeight:'500',
            backgroundColor: 'transparent',
          }}
        >
          <FiPlusCircle /> &nbsp;&nbsp;Add School
        </button>
      </div>
      <div className="grid grid-cols-3 gap-6 sm:grid-cols-4 md:grid-cols-6">
        {schools.map((school) => (
          <div 
            key={school.id} 
            className="text-center cursor-pointer"
            onClick={() => handleSchoolClick(school.id)}
          >
            <div
              className="flex items-center justify-center mx-auto bg-red-100 rounded-full"
              style={{
                width: '80px',
                height: '80px',
              }}
            >
              <img
                src={school.logo ? school.logo : defaultImage} // Use the fetched logo or default image
                alt={school.name}
                className="w-4/5 h-auto rounded-full"
              />
            </div>
            <p className="mt-2 text-lg font-normal">{school.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SchoolDashboard;
