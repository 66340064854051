import React, { useEffect, useRef, useState, useCallback } from "react";
import './filter.scss';
import { CiFilter } from "react-icons/ci";
import { Select, Option } from "@material-tailwind/react";
import { AdjustmentsHorizontalIcon, EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import debounce from 'lodash/debounce';

function Filter(props) {
    const {classesList=[], sectionsList=[], selectedClassValue='', selectedSectionValue='', clearFilters=false} = props;
    const [classList, setClassList] = useState([...classesList]);
    const [sections, setSections] = useState([...sectionsList]);
    const [years, setYears] = useState([]);
    const [searchBy, setSearchBy] = useState('');
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedClass, setSelectedClass] = useState(selectedClassValue);
    const [selectedSection, setSelectedSection] = useState(selectedSectionValue);
    const classRef = useRef(null);
    const selectRef = useRef(null);
    const yearRef = useRef(null);

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const fetchedYears = Array.from({ length: 5 }, (_, i) => `${currentYear - i}-${currentYear - i + 1}`);
        setYears(fetchedYears);
    }, []);

    useEffect(() => {
        if(clearFilters) {
            setSearchBy('');
            setSelectedYear(null);
            setSelectedClass(null);
            setSelectedSection(null);
        }
    }, [clearFilters]);

    useEffect(() => {
        setClassList(classesList);
    }, [classesList]);

    useEffect(() => {
        setSections(sectionsList);
    }, [sectionsList]);

    useEffect(() => {
        setSelectedClass(selectedClassValue);
    }, [selectedClassValue]);

    useEffect(() => {
        setSelectedSection(selectedSectionValue);
    }, [selectedSectionValue]);

    const updateFilters = useCallback(
        debounce((newFilters) => {
            props.getFilterInputs(newFilters);
        }, 300),
        []
    );

    const handleSearchChange = (e) => {
        const searchTerm = e.target.value;
        setSearchBy(searchTerm);
        updateFilters({
            searchBy: searchTerm,
            year: selectedYear,
            className: selectedClass,
            section: selectedSection
        });
    };

    const getSelectedYear = (val) => {
        setSelectedYear(val);
        updateFilters({
            searchBy,
            year: val,
            className: selectedClass,
            section: selectedSection
        });
    };

    const getSelectedClass = (val) => {
        setSelectedClass(val);
        props.getClassSelection(val);
        setSelectedSection(null);
        updateFilters({
            searchBy,
            year: selectedYear,
            className: val,
            section: null
        });
    };

    const getSelectedSection = (val) => {
        setSelectedSection(val);
        updateFilters({
            searchBy,
            year: selectedYear,
            className: selectedClass,
            section: val
        });
    };

    return (
        <div className="filter-container">
            <div className="searchField">
                {/* <input
                    type="search"
                    placeholder="Search By Name"
                    className="search border border-gray-300 rounded-md p-2 shadow-sm"
                    value={searchBy || ''}
                    onChange={handleSearchChange}
                /> */}
            </div>

            <div className="filter-container" style={{paddingTop:'0%'}}>
                <div className="drop">
                    <select
                        value={selectedYear || ''}
                        onChange={(e) => getSelectedYear(e.target.value)}
                        ref={yearRef}
                        className="select-dropdowns border border-gray-300 rounded-md p-2 shadow-sm"
                    >
                        <option value="" disabled>Select Year</option>
                        {years.map(year => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="drop">
                    <select
                        value={selectedClass || ''}
                        ref={classRef}
                        onChange={(e) => getSelectedClass(e.target.value)}
                        className="select-dropdowns border border-gray-300 rounded-md p-2 shadow-sm"
                    >
                        <option value="" disabled>Select Class</option>
                        {classList.map(cls => (
                            <option key={cls} value={cls}>
                                {cls}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="drop">
                    <select
                        value={selectedSection || ''}
                        ref={selectRef}
                        onChange={(e) => getSelectedSection(e.target.value)}
                        className="select-dropdowns border border-gray-300 rounded-md p-2 shadow-sm"
                    >
                        <option value="" disabled>Select Section</option>
                        {sections.map(sec => (
                            <option key={sec} value={sec}>
                                {sec}
                            </option>
                        ))}
                    </select>
                </div>
                {/* <div className="filter-icon">
                    <CiFilter />
                </div>
                <div className="filter-icon">
                    <AdjustmentsHorizontalIcon height={18} width={18} />
                </div>
                <div className="filter-icon">
                    <EllipsisVerticalIcon height={18} width={18} />
                </div> */}
            </div>
        </div>
    );
}

export default React.memo(Filter);