import React from "react";
import './errorPage.scss';
import { MdRefresh } from "react-icons/md";
import { Button } from "@material-tailwind/react";
import { useLocation } from 'react-router-dom'; 
import { useNavigate } from 'react-router-dom';

const ErrorPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { errorMessage, statusCode } = location.state || {};

  const handleRefresh = () => {
    if (location.state && location.state.from) {
      navigate(location.state.from);
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen bg-gray-100 w-full">
      <img
        src="\images\Layer 1.png"
        alt="Error"
        className="w-1/5 mb-8"
      />
      <p className="text-gray-600 text-center mb-4">
        {errorMessage || "We can't seem to reach the server right now. Check your Wi-Fi or internet connection and try again."}
      </p>
      {statusCode && <p className="text-gray-500 mb-4">Status Code: {statusCode}</p>}
      <Button 
        variant="outlined"  
        className="bg-gray-200 text-gray-500 px-4 py-2 rounded-lg hover:bg-gray-300 flex items-center justify-evenly" 
        onClick={handleRefresh}
      >
        Refresh <MdRefresh />
      </Button>
    </div>
  );
};

export default ErrorPage;